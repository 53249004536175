@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-loan {
  max-width: 1424px;
  margin: auto;
  min-height: 100vh;

  .j9bc-loancom_main_left_div_input,.j9bc-lendingcom_main_left_div_input {
    border-radius: 8px;
    background: rgba(38, 38, 41, 1);
    border: 1px solid rgba(69, 69, 89, 1);

  }
}

@media screen and (max-width:960px) {
  .j9bc-loan {
    // width: calc(100% - 32px);
    margin: auto;
  }
}