@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-pledge__action {
	border-radius: 16px;
	padding: 200px 16px 16px;
	background: no-repeat 60% 0/100% url("../img/质押-h5.png") #1d2a43;
	position: relative;
	grid-area: action;

	.md({
    background: no-repeat 32px/160px url("../img/质押.png") #1d2a43;
    padding-top:16px;
    padding-left: 200px;
    min-height: 286px;
  });

	&__items {
		display: grid;
		gap: 16px;
		.md({
      height: 100%;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      align-content: center;
    });
	}

	&__item {
		width: 100%;
		background: rgba(0, 0, 0, 0.2);
		border-radius: 8px;
		padding: 16px;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.md({
      background: none;
    });
		.j9bc-btn {
			width: 100px;
		}
	}

	&__label {
		font-size: 14px;
		font-weight: 600;
		line-height: 22px;
		color: #9db1d8;
	}

	&__value {
		font-family: Montserrat, sans-serif;
		font-size: 22px;
		line-height: 32px;
		color: #fff;
	}

	&__name {
		color: #9db1d8;
		font-family: PingFang SC;
		font-weight: regular;
		font-size: 14px;
		line-height: 22px;
		letter-spacing: 0px;
		text-align: left;
	}

	&__cell {
		&__label {
			color: #9db1d8;
			font-size: 14px;
			line-height: 22px;
			margin-top: 8px;
		}
		&__value {
			display: grid;
			grid-template-columns: 20px 1fr;
			place-items: center start;
			gap: 8px;
			padding-left: 16px;
			margin-top: 8px;
			// width: 196px;
			height: 44px;
			color: #ffffff;
			font-size: 16px;
			border-radius: 10px;
			background: #33415d;
		}
	}
}

.j9bc-pledge__pledge-record {
	table {
		width: 100%;
		color: #fff;
	}
	th,
	td {
		height: 40px;
		&[colspan] {
			text-align: center;
      height: 200px;
		}
	}
	th {
		color: #9db1d8;
		font-size: 14px;
	}
	.rc-pagination {
		text-align: center;
	}
}

.j9bc-pager {
	display: grid;
	grid-auto-flow: column;
	place-items: center center;
	place-content: center center;
	gap: 4px;

	&-item,
	.j9bc-pager-prev,
	.j9bc-pager-next {
		width: 32px;
		height: 32px;
		display: flex;
		align-items: center;
		border-radius: 8px;
		justify-content: center;
		border: none !important;
		margin-right: 4px !important;
		background: #33415dd8 !important;

		&-active {
			background: #6fa9fa !important;
		}
	}

	&-disabled {
		cursor: not-allowed;
		opacity: 0.8;
	}
}
