ns = 'j9bc-'
e-prefix    ?= '__'
m-prefix    ?= '--'
m-delimiter ?= '-'
group-store = ()

str()
  join('', arguments)

b(name)
  .{ns}{name}
    {block}

group()
  caller = called-from[0]
  level = length(called-from) + 1
  elements = group-store[level]
  selector = ()
  parent = null
  {join(',', elements)}
    {block}
  group-store[level] = null

m(mod, val=null)
  val    = val && m-delimiter + val
  mod    = m-prefix + mod
  mod    = val ? mod + val : mod
  caller = called-from[0]
  if caller in ('group')
    level = length(called-from)
    mod = str('&', mod)
    if group-store[level] == null
      group-store[level] = mod
    else
      push(group-store[level], mod)
  &{mod}
    {block}

e(element, val=null)
  val     = val && m-delimiter + val
  element = e-prefix + element
  element = val ? element + val : element
  caller  = called-from[0]
  gcaller = called-from[1]
  if caller in ('group')
    level = length(called-from)
    if gcaller in ('e' 'm')
      element = str('& ^[0]', element)
    else
      element = str('^[0]', element)
    if group-store[level] == null
      group-store[level] = element
    else
      push(group-store[level], element)
  else
      &{element}
        {block}

$xs=0px
$sm=576px
$md=768px
$lg=992px
$xl=1200px



md(){
  @media screen and (min-width: $md) {
		{block}
	}
}

ltmd(){
  @media screen and (max-width: $md - 1) {
		{block}
	}
}


zh(  )
  &:lang(zh)
    {block}

en(  )
  &:lang(en)
    {block}
