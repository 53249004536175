@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-loanheader {
  &-title {
    color: rgba(255, 255, 255, 1);
    font-size: 24px;
    line-height: 40px;
  }

  &-databox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;

    &_li {
      border-radius: 16px;
      padding: 16px;
      // width: 272px;
      width: 344px;
      margin-right: 16px;
      background: rgba(40, 42, 47, 1);

      &_title {
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        line-height: 1.5;
      }

      &_num {
        margin-top: 8px;
        color: rgba(255, 255, 255, 1);
        font-size: 22px;
        line-height: 22px;
      }

      &:first-child {
        background: linear-gradient(-64.34deg, rgba(230, 91, 131, 1) 0%, rgba(211, 106, 77, 1) 100%);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width:960px) {
  .j9bc-loanheader {
    &-title {
      margin: 16px 0 12px 0;
      color: rgba(255, 255, 255, 1);
      font-weight: 600;
      font-size: 18px;
      line-height: 40px;
    }

    &-databox {
      grid-template-rows: auto;
      display: grid;
      grid-gap: 8px;
      gap: 8px;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      margin-top: 12px;
      grid-template-columns: 1fr 1fr;
      &_li {
        border-radius: 8px;
        padding: 8px 16px ;
        width: auto;
        background: rgba(40, 42, 47, 1);
        margin-right: 0;
        &_title {
          color: rgba(136,136,136,1);
          font-size: 12px;
          line-height: 18px;     
        }

        &_num {
          margin-top: 4px;
          color: rgba(224,224,224,1);
          font-size: 18px;
          line-height: 1;        
        }

        &:first-child {
          width: 100%;
          background: linear-gradient(-64.34deg, rgba(230, 91, 131, 1) 0%, rgba(211, 106, 77, 1) 100%);
          // grid-column: span 2;
          .j9bc-loanheader-databox_li_title{
            color: rgba(224,224,224,1);
          }
        }
        &:nth-child(2){
          width: 100%;
          background: linear-gradient(-64.34deg, rgba(120, 151, 229, 1) 0%, rgba(38, 85, 203, 1) 100%);
          // grid-column: span 2;
          .j9bc-loanheader-databox_li_title{
            color: rgba(224,224,224,1);
          }
        }
        &:nth-child(3){
          display:none;
          width: 100%;
          // background: linear-gradient(-64.34deg, rgba(120, 151, 229, 1) 0%, rgba(38, 85, 203, 1) 100%);
          background: rgba(40, 42, 47, 1);
          // grid-column: span 2;
          .j9bc-loanheader-databox_li_title{
            color: rgba(224,224,224,1);
          }
        }
        &:nth-child(4){
          // display:none;
          width: 100%;
          // background: linear-gradient(-64.34deg, rgba(120, 151, 229, 1) 0%, rgba(38, 85, 203, 1) 100%);
          background: rgba(40, 42, 47, 1);
          // grid-column: span 2;
          .j9bc-loanheader-databox_li_title{
            color: rgba(224,224,224,1);
          }
        }
        &:nth-child(5){
          // display:none;
          width: 100%;
          background: rgba(40, 42, 47, 1);
          // grid-column: span 2;
          .j9bc-loanheader-databox_li_title{
            color: rgba(224,224,224,1);
          }
        }
        &:nth-child(6){
          display:none;
          width: 100%;
          background: linear-gradient(-64.34deg, rgba(120, 151, 229, 1) 0%, rgba(38, 85, 203, 1) 100%);
          // grid-column: span 2;
          .j9bc-loanheader-databox_li_title{
            color: rgba(224,224,224,1);
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}