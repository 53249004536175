.j9bc-field
  position relative
  display flex
  flex-wrap wrap
  align-items center
  padding 0 16px
  border 1px solid #24314a
  border-radius 4px
  color rgba( 157, 177, 216, 0.7 )
  font-size var( --font-size, 14px )
  transition border 200ms
  gap 8px

  &:focus-within
    border-color #657697
    color #9db1d8

    .hx-field__fake::after
      animation-name blink

  &--mini
    padding 0 8px
    height 22px
    font-size 12px
    gap 4px

  &--small
    padding 0 8px
    height 30px
    --font-size 12px
    gap 4px

  &--normal
    height 40px
    --font-size 14px

  &--large
    height 48px
    --font-size 16px

  &__header
    &--vertical
      width 100%

  &__body
    position relative
    display flex
    flex 1
    align-self stretch
    align-items center
    gap 8px

  &__label
    position relative
    display flex
    flex 1
    align-self stretch
    cursor text

  &__value
    flex 1
    align-self stretch
    width 0
    outline 0
    border 0
    background-color transparent
    color inherit
    font inherit
    line-height 1

    &--amount
      opacity 0

  &__fake
    position absolute
    display flex
    align-items center
    pointer-events none
    left 0
    right 0
    top 0
    bottom 0

    &::after
      height 1em
      border-left 1px solid transparent
      content ""
      animation 1s step-end infinite

  &__footer
    position absolute
    top 110%
    color red
    font-size 12px
    line-height 1.2

@keyframes blink
  from
  to
    border-color transparent

  50%
    border-color currentColor
