@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.empty_box{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  // margin-top: 68px;
  flex-wrap: wrap;
  .empty{
    img{
      width: 118px;
      display: block;
    }
    .tip{
      color: rgba(255, 255, 255, 0.4);
      margin-top: 16px;
      text-align: center;
    }
  }
}