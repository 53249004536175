.j9bc-toast
  --j9bc-toastify-color-light #fff
  --j9bc-toastify-color-dark #121212
  --j9bc-toastify-color-info #3498db
  --j9bc-toastify-color-success rgba(0,0,0,0.8)
  --j9bc-toastify-color-warning #f1c40f
  --j9bc-toastify-color-error #e74c3c
  --j9bc-toastify-color-transparent rgba( 255, 255, 255, 0.7 )
  --j9bc-toastify-icon-color-info var( --j9bc-toastify-color-info )
  --j9bc-toastify-icon-color-success var( --j9bc-toastify-color-success )
  --j9bc-toastify-icon-color-warning var( --j9bc-toastify-color-warning )
  --j9bc-toastify-icon-color-error var( --j9bc-toastify-color-error )
  --j9bc-toastify-toast-width 320px
  --j9bc-toastify-toast-background #fff
  --j9bc-toastify-toast-min-height 64px
  --j9bc-toastify-toast-max-height 800px
  --j9bc-toastify-font-family sans-serif
  --j9bc-toastify-z-index 9999
  --j9bc-toastify-text-color-light #757575
  --j9bc-toastify-text-color-dark #fff
  --j9bc-toastify-text-color-info #fff
  --j9bc-toastify-text-color-success #3dc37e
  --j9bc-toastify-text-color-warning #fff
  --j9bc-toastify-text-color-error #fff
  --j9bc-toastify-spinner-color #616161
  --j9bc-toastify-spinner-color-empty-area #e0e0e0
  --j9bc-toastify-color-progress-light linear-gradient( 
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
   )
  --j9bc-toastify-color-progress-dark #bb86fc
  --j9bc-toastify-color-progress-info var( --j9bc-toastify-color-info )
  --j9bc-toastify-color-progress-success var( --j9bc-toastify-color-success )
  --j9bc-toastify-color-progress-warning var( --j9bc-toastify-color-warning )
  --j9bc-toastify-color-progress-error var( --j9bc-toastify-color-error )

  .j9bc-Toastify__toast-container
    position fixed
    z-index var( --j9bc-toastify-z-index )
    box-sizing border-box
    padding 4px
    width var( --j9bc-toastify-toast-width )
    color #fff
    -webkit-transform translate3d( 0, 0, var( --j9bc-toastify-z-index ) px )

  .j9bc-Toastify__toast-container--top-left
    top 1em
    left 1em

  .j9bc-Toastify__toast-container--top-center
    top 50%
    left 50%
    transform translate( -50%, -50% )

  .j9bc-Toastify__toast-container--top-right
    top 1em
    right 1em

  .j9bc-Toastify__toast-container--bottom-left
    bottom 1em
    left 1em

  .j9bc-Toastify__toast-container--bottom-center
    bottom 1em
    left 50%
    transform translateX( -50% )

  .j9bc-Toastify__toast-container--bottom-right
    right 1em
    bottom 1em

  @media only screen and ( max-width 480px )
    // .j9bc-Toastify__toast-container
    //   left 0
    //   margin 0
    //   padding 0
    //   width 100vw

    // // .j9bc-Toastify__toast-container--top-left
    // // .j9bc-Toastify__toast-container--top-center
    // // .j9bc-Toastify__toast-container--top-right
    // //   top 0
    // //   transform translateX( 0 )

    // .j9bc-Toastify__toast-container--bottom-left
    // .j9bc-Toastify__toast-container--bottom-center
    // .j9bc-Toastify__toast-container--bottom-right
    //   bottom 0
    //   transform translateX( 0 )

    // .j9bc-Toastify__toast-container--rtl
    //   right 0
    //   left initial

  .Toastify__toast
    position relative
    display -ms-flexbox
    display flex
    justify-content space-between
    overflow hidden
    box-sizing border-box
    margin-bottom 1rem
    padding 8px
    padding 8px 16px
    min-height var( --j9bc-toastify-toast-min-height )
    min-height unset
    max-height var( --j9bc-toastify-toast-max-height )
    border-radius 4px
    border-radius 8px
    background-color rgba( 0, 0, 0, 0.8 )
    box-shadow 0 1px 10px 0 rgba( 0, 0, 0, 0.1 ), 0 2px 15px 0 rgba( 0, 0, 0, 0.05 )
    box-shadow none
    text-align center
    white-space pre-wrap
    font-size 14px
    font-family var( --j9bc-toastify-font-family )
    line-height 22px
    cursor pointer
    direction ltr
    -ms-flex-pack justify

  .Toastify__toast--rtl
    direction rtl

  .Toastify__toast-body
    display -ms-flexbox
    display flex
    flex 1 1 auto
    align-items center
    margin auto 0
    padding 0
    -ms-flex 1 1 auto
    -ms-flex-align center

  .Toastify__toast-body > div:last-child
    flex 1
    -ms-flex 1

  .Toastify__toast-icon
    display -ms-flexbox
    display flex
    flex-shrink 0
    width 20px
    -webkit-margin-end 10px
    margin-inline-end 10px
    -ms-flex-negative 0

  .Toastify--animate
    animation-duration 0.7s
    animation-fill-mode both

  .Toastify--animate-icon
    animation-duration 0.3s
    animation-fill-mode both

  @media only screen and ( max-width 480px )
    .Toastify__toast
      margin-bottom 0
      border-radius 0

  .Toastify__toast-theme--dark
    background var( --j9bc-toastify-color-dark )
    color var( --j9bc-toastify-text-color-dark )

  .Toastify__toast-theme--light
    background var( --j9bc-toastify-color-light )
    color var( --j9bc-toastify-text-color-light )

  .Toastify__toast-theme--colored.Toastify__toast--default
    background var( --j9bc-toastify-color-light )
    color var( --j9bc-toastify-text-color-light )

  .Toastify__toast-theme--colored.Toastify__toast--info
    background var( --j9bc-toastify-color-info )
    color var( --j9bc-toastify-text-color-info )

  .Toastify__toast-theme--colored.Toastify__toast--success
    background var( --j9bc-toastify-color-success )
    color var( --j9bc-toastify-text-color-success )

  .Toastify__toast-theme--colored.Toastify__toast--warning
    background var( --j9bc-toastify-color-warning )
    color var( --j9bc-toastify-text-color-warning )

  .Toastify__toast-theme--colored.Toastify__toast--error
    background var( --j9bc-toastify-color-error )
    color var( --j9bc-toastify-text-color-error )

  .Toastify__progress-bar-theme--light
    background var( --j9bc-toastify-color-progress-light )

  .Toastify__progress-bar-theme--dark
    background var( --j9bc-toastify-color-progress-dark )

  .Toastify__progress-bar--info
    background var( --j9bc-toastify-color-progress-info )

  .Toastify__progress-bar--success
    background var( --j9bc-toastify-color-progress-success )

  .Toastify__progress-bar--warning
    background var( --j9bc-toastify-color-progress-warning )

  .Toastify__progress-bar--error
    background var( --j9bc-toastify-color-progress-error )

  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--info
  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success
  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning
  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error
    background var( --j9bc-toastify-color-transparent )

  .Toastify__close-button
    align-self flex-start
    padding 0
    outline none
    border none
    background transparent
    color #fff
    opacity 0.7
    cursor pointer
    transition 0.3s ease
    -ms-flex-item-align start

  .Toastify__close-button--light
    color #000
    opacity 0.3

  .Toastify__close-button > svg
    width 14px
    height 16px
    fill currentColor

  .Toastify__close-button:hover
  .Toastify__close-button:focus
    opacity 1

  @keyframes Toastify__trackProgress
    0%
      transform scaleX( 1 )

    100%
      transform scaleX( 0 )

  .Toastify__progress-bar
    position absolute
    bottom 0
    left 0
    z-index var( --j9bc-toastify-z-index )
    width 100%
    height 5px
    opacity 0.7
    transform-origin left

  .Toastify__progress-bar--animated
    animation Toastify__trackProgress linear 1 forwards

  .Toastify__progress-bar--controlled
    transition transform 0.2s

  .Toastify__progress-bar--rtl
    right 0
    left initial
    transform-origin right

  .Toastify__spinner
    box-sizing border-box
    width 20px
    height 20px
    border 2px solid
    border-color var( --j9bc-toastify-spinner-color-empty-area )
    border-right-color var( --j9bc-toastify-spinner-color )
    border-radius 100%
    animation Toastify__spin 0.65s linear infinite

  @keyframes Toastify__bounceInRight
    from
    60%
    75%
    90%

    to
      animation-timing-function cubic-bezier( 0.215, 0.61, 0.355, 1 )

    from
      opacity 0
      transform translate3d( 3000px, 0, 0 )

    60%
      opacity 1
      transform translate3d( -25px, 0, 0 )

    75%
      transform translate3d( 10px, 0, 0 )

    90%
      transform translate3d( -5px, 0, 0 )

    to
      transform none

  @keyframes Toastify__bounceOutRight
    20%
      opacity 1
      transform translate3d( -20px, 0, 0 )

    to
      opacity 0
      transform translate3d( 2000px, 0, 0 )

  @keyframes Toastify__bounceInLeft
    from
    60%
    75%
    90%

    to
      animation-timing-function cubic-bezier( 0.215, 0.61, 0.355, 1 )

    0%
      opacity 0
      transform translate3d( -3000px, 0, 0 )

    60%
      opacity 1
      transform translate3d( 25px, 0, 0 )

    75%
      transform translate3d( -10px, 0, 0 )

    90%
      transform translate3d( 5px, 0, 0 )

    to
      transform none

  @keyframes Toastify__bounceOutLeft
    20%
      opacity 1
      transform translate3d( 20px, 0, 0 )

    to
      opacity 0
      transform translate3d( -2000px, 0, 0 )

  @keyframes Toastify__bounceInUp
    from
    60%
    75%
    90%

    to
      animation-timing-function cubic-bezier( 0.215, 0.61, 0.355, 1 )

    from
      opacity 0
      transform translate3d( 0, 3000px, 0 )

    60%
      opacity 1
      transform translate3d( 0, -20px, 0 )

    75%
      transform translate3d( 0, 10px, 0 )

    90%
      transform translate3d( 0, -5px, 0 )

    to
      transform translate3d( 0, 0, 0 )

  @keyframes Toastify__bounceOutUp
    20%
      transform translate3d( 0, -10px, 0 )

    40%
    45%
      opacity 1
      transform translate3d( 0, 20px, 0 )

    to
      opacity 0
      transform translate3d( 0, -2000px, 0 )

  @keyframes Toastify__bounceInDown
    from
    60%
    75%
    90%

    to
      animation-timing-function cubic-bezier( 0.215, 0.61, 0.355, 1 )

    0%
      opacity 0
      transform translate3d( 0, -3000px, 0 )

    60%
      opacity 1
      transform translate3d( 0, 25px, 0 )

    75%
      transform translate3d( 0, -10px, 0 )

    90%
      transform translate3d( 0, 5px, 0 )

    to
      transform none

  @keyframes Toastify__bounceOutDown
    20%
      transform translate3d( 0, 10px, 0 )

    40%
    45%
      opacity 1
      transform translate3d( 0, -20px, 0 )

    to
      opacity 0
      transform translate3d( 0, 2000px, 0 )

  .Toastify__bounce-enter--top-left
  .Toastify__bounce-enter--bottom-left
    animation-name Toastify__bounceInLeft

  .Toastify__bounce-enter--top-right
  .Toastify__bounce-enter--bottom-right
    animation-name Toastify__bounceInRight

  .Toastify__bounce-enter--top-center
    animation-name Toastify__bounceInDown

  .Toastify__bounce-enter--bottom-center
    animation-name Toastify__bounceInUp

  .Toastify__bounce-exit--top-left
  .Toastify__bounce-exit--bottom-left
    animation-name Toastify__bounceOutLeft

  .Toastify__bounce-exit--top-right
  .Toastify__bounce-exit--bottom-right
    animation-name Toastify__bounceOutRight

  .Toastify__bounce-exit--top-center
    animation-name Toastify__bounceOutUp

  .Toastify__bounce-exit--bottom-center
    animation-name Toastify__bounceOutDown

  @keyframes Toastify__zoomIn
    from
      opacity 0
      transform scale3d( 0.3, 0.3, 0.3 )

    50%
      opacity 1

  @keyframes Toastify__zoomOut
    from
      opacity 1

    50%
      opacity 0
      transform scale3d( 0.3, 0.3, 0.3 )

    to
      opacity 0

  .Toastify__zoom-enter
    animation-name Toastify__zoomIn

  .Toastify__zoom-exit
    animation-name Toastify__zoomOut

  @keyframes Toastify__flipIn
    from
      opacity 0
      transform perspective( 400px ) rotate3d( 1, 0, 0, 90deg )
      animation-timing-function ease-in

    40%
      transform perspective( 400px ) rotate3d( 1, 0, 0, -20deg )
      animation-timing-function ease-in

    60%
      opacity 1
      transform perspective( 400px ) rotate3d( 1, 0, 0, 10deg )

    80%
      transform perspective( 400px ) rotate3d( 1, 0, 0, -5deg )

    to
      transform perspective( 400px )

  @keyframes Toastify__flipOut
    from
      transform perspective( 400px )

    30%
      opacity 1
      transform perspective( 400px ) rotate3d( 1, 0, 0, -20deg )

    to
      opacity 0
      transform perspective( 400px ) rotate3d( 1, 0, 0, 90deg )

  .Toastify__flip-enter
    animation-name Toastify__flipIn

  .Toastify__flip-exit
    animation-name Toastify__flipOut

  @keyframes Toastify__slideInRight
    from
      visibility visible
      transform translate3d( 110%, 0, 0 )

    to
      transform translate3d( 0, 0, 0 )

  @keyframes Toastify__slideInLeft
    from
      visibility visible
      transform translate3d( -110%, 0, 0 )

    to
      transform translate3d( 0, 0, 0 )

  @keyframes Toastify__slideInUp
    from
      visibility visible
      transform translate3d( 0, 110%, 0 )

    to
      transform translate3d( 0, 0, 0 )

  @keyframes Toastify__slideInDown
    from
      visibility visible
      transform translate3d( 0, -110%, 0 )

    to
      transform translate3d( 0, 0, 0 )

  @keyframes Toastify__slideOutRight
    from
      transform translate3d( 0, 0, 0 )

    to
      visibility hidden
      transform translate3d( 110%, 0, 0 )

  @keyframes Toastify__slideOutLeft
    from
      transform translate3d( 0, 0, 0 )

    to
      visibility hidden
      transform translate3d( -110%, 0, 0 )

  @keyframes Toastify__slideOutDown
    from
      transform translate3d( 0, 0, 0 )

    to
      visibility hidden
      transform translate3d( 0, 500px, 0 )

  @keyframes Toastify__slideOutUp
    from
      transform translate3d( 0, 0, 0 )

    to
      visibility hidden
      transform translate3d( 0, -500px, 0 )

  .Toastify__slide-enter--top-left
  .Toastify__slide-enter--bottom-left
    animation-name Toastify__slideInLeft

  .Toastify__slide-enter--top-right
  .Toastify__slide-enter--bottom-right
    animation-name Toastify__slideInRight

  .Toastify__slide-enter--top-center
    animation-name Toastify__slideInDown

  .Toastify__slide-enter--bottom-center
    animation-name Toastify__slideInUp

  .Toastify__slide-exit--top-left
  .Toastify__slide-exit--bottom-left
    animation-name Toastify__slideOutLeft

  .Toastify__slide-exit--top-right
  .Toastify__slide-exit--bottom-right
    animation-name Toastify__slideOutRight

  .Toastify__slide-exit--top-center
    animation-name Toastify__slideOutUp

  .Toastify__slide-exit--bottom-center
    animation-name Toastify__slideOutDown

  @keyframes Toastify__spin
    from
      transform rotate( 0 )

    to
      transform rotate( 360deg )

/* # sourceMappingURL=ReactToastify.css.map */
