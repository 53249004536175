@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-nodatabox {
	margin-top: 25px;
	.j9bc-nodata_imgbox {
		display: flex;
		align-items: center;
		justify-content: center;
		&-img {
			width: 118px;
		}
	}
	&-p {
		color: rgba(255, 255, 255, 0.4);
		margin-top: 16px;
		text-align: center;
		font-size: 14px;
	}
}
.j9bc-closebtn {
	position: absolute;
	top: 0;
	right: 20px;
	width: 14px;
	height: 14px;
	cursor: pointer;
}

.j9bc-RechargeRecord_title {
	color: #ffffff;
	font-size: 24px;
	line-height: 36px;
	letter-spacing: 0px;
	text-align: left;
}

.j9bc-rr_del_title {
	display: grid;
	color: #99acd0;
	font-family: Montserrat;
	font-weight: bold;
	font-size: 16px;
	margin-top: 24px;
	line-height: 24px;
	grid-template-columns: 1fr 1fr 1fr;
  white-space: nowrap;
  .md({
    grid-template-columns: repeat(4,1fr);
  })
}

.j9bc-rr_del {
	line-height: 64px;
	display: grid;
  grid-template-columns:1fr 1fr 1fr;
	align-items: center;
	border-bottom: 1px solid #50607f33;
	color: #ffffff;
	font-family: Montserrat;
	font-weight: bold;
	font-size: 16px;
  white-space: nowrap;
  .md({
    grid-template-columns: repeat(4,1fr);
  });
	

	.j9bc-status {
		width: 67px;
		color: #ffffff;
	}

	.j9bc-stayusfail {
		color: #da384e;
	}

	.j9bc-imgbox {
		display: flex;
		align-items: center;

		img {
			width: 20px;
			height: 20px;
			margin-right: 8px;
		}

		span {
			// overflow: hidden;
			// font-size: 12px;
			// text-overflow:ellipsis;
			// white-space: nowrap;
		}
	}
}

.j9bc-nomoredata {
	color: #ffffff;
	font-family: Montserrat;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0px;
	text-align: center;
	margin-top: 24px;
	margin-bottom: 10px;
}

.j9bc-re_pagination {
	display: flex;
	justify-content: center;
	margin-bottom: 16px;
	margin-top: 24px;
	.j9bc-re_prev {
		background: #33415dd8;
		span {
			color: #9db1d8;
		}
	}

	.j9bc-re_next {
		background: #33415dd8;
		span {
			color: #9db1d8;
		}
	}
}

.rc-pagination .rc-pagination-disabled .j9bc-re_next {
	span {
		opacity: 0.15000000596046448;
	}
}
.rc-pagination .rc-pagination-disabled .j9bc-re_prev {
	span {
		opacity: 0.15000000596046448;
	}
}

@media screen and (max-width: 960px) {
	.j9bc-closebtn {
		position: absolute;
		top: 0;
		right: 14px;
		width: 14px !important;
		height: 14px;
		cursor: pointer;
	}

	.j9bc-RechargeRecord_title {
		margin-top: 16px;
	}

	.j9bc-re_pagination {
		overflow-x: scroll;
		padding-bottom: 10px;
		width: 100%;
	}
}

.j9bc-rr_pop .hx-modal .hx-modal_page {
	height: auto;
	width: calc(100% - 16px);
	border-radius: 16px;
	max-height: 100%;
	overflow: auto;
}
