@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-pledge__header {
	padding: 132px 16px 0 16px;
	height: 280px;
	background: #1d2a43 no-repeat top/100% 150px url("../img/分红-h5.png");
	display: grid;
	grid-template-columns: 1fr 1fr;
	border-radius: 5vw;
  grid-area: header;
	.md({
    background: #1d2a43 no-repeat left center/auto 132px url("../img/分红@2x.png");
    grid-template-columns: repeat(4,1fr);
    height: 132px;
    padding-top: 0;
    padding-left: 200px;
    border-radius: 16px;
    align-items: center;
  });

	&__item {
    .md({
      background: no-repeat right 30% center/auto 20px url("../img/箭头@2x.png");
    });
		&:last-child {
			background: none;
		}
	}

	&__label {
		color: #9db1d8;
		font-size: 14px;
		font-weight: 600;
		line-height: 22px;
	}
	&__value {
		color: #fff;
		font-size: 18px;
		font-weight: 600;
		line-height: 28px;
	}
}
