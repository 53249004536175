@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-Huigou-Wrap{
  // width: 465px;
  // height: 260px;
  border-radius: 16px;
  background: rgba(29,42,67,0.8);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 24px;
  &-left{
    // width: 287px;
    &-p{
     &-1{
        color: rgba(187,191,199,1);
        font-size: 18px;
        line-height: 27px;
        line-height: 27px;
        padding-bottom: 4px;
      }
      &-2{
        color: rgba(255,176,27,1);
        font-family: Montserrat;
        font-size: 40px;
        line-height: 48px;
        font-weight: bold;
      }
     &-3{
        color: rgba(255,176,27,1);
        font-size: 16px;
        line-height: 24px;
      }
      &-4{
        color: rgba(200,131,0,1);
        font-size: 12px;
        line-height: 22px;
        margin-top: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &-img{
          width: 12px;
          margin-right: 4px;
        }
      }
    }
    &-bto{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 65px;
      margin-top: 18px;
      &-li{
        &-1{
          padding-right: 16px;
        }
       &-3{
          padding-left:16px;
        }
        &-2{
          position: relative;
          width: 0;
          &::after{
            content: '';
            height: 16px;
            width: 1px;
            background: rgba(153,172,208,0.2);
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0,-50%);
          }
        }
        &-span{
          color: rgba(187,191,199,1);
          font-family: PingFang SC;
          font-size: 12px;
          line-height: 18px;
          display: block;
        }
        &-strong{
          color: rgba(187,191,199,1);
          font-family: PingFang SC;
          font-weight: semibold;
          font-size: 16px;
          line-height: 24px;
          margin-top: 4px;
          display: block;
        }
      }
    }
  }
  &-right{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    &-bto{
      width: 130px;
      height: 65px;
      border-radius: 8px;
      background: rgba(96,183,191,0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &-p{
        &:nth-child(1){
          color: rgba(96,183,191,1);
          font-family: PingFang SC;
          font-weight: regular;
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 4px;
          text-align: center;
        }
        &:nth-child(2){
          color: rgba(96,183,191,1);
          font-family: PingFang SC;
          font-weight: bold;
          font-size: 18px;
          line-height: 27px;
        }
      }

    }
  }
  &.en{
    // height: 290px;
    .Huigou-Wrap-right-bto{
      height: 83px;
    }
    .Huigou-Wrap-left-bto{
      margin-top: 35px;
    }
  }

}
@media screen and (max-width: 768px) {
  .j9bc-Huigou-Wrap{
    width: 100%;
    margin-bottom: 16px;
    // height: 300px!important;
    border-radius: 16px;
    background: rgba(29,42,67,0.8);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 24px;
    &-left{
      width: auto;
      P{
        &:nth-child(1){
          color: rgba(187,191,199,1);
          font-size: 18px;
          line-height: 27px;
          line-height: 27px;
          padding-bottom: 4px;
        }
        &:nth-child(2){
          color: rgba(255,176,27,1);
          font-family: Montserrat;
          font-size: 40px;
          line-height: 48px;
          font-weight: bold;
        }
        &:nth-child(3){
          color: rgba(255,176,27,1);
          font-size: 14px;
          line-height: 24px;
        }
        &:nth-child(4){
          color: rgba(200,131,0,1);
          font-size: 12px;
          line-height: 22px;
          margin-top: 8px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          img{
            width: 12px;
            margin-right: 4px;
          }
        }
      }
      &-bto{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100px;
        margin-top: 18px;
        flex-direction: column;
        li{
          &:nth-child(1){
            padding-right: 0;
          }
          &:nth-child(3){
            padding-left:0;
            margin-top: 8px;
          }
          &:nth-child(2){
            display: none;
          }
          span{
            color: rgba(187,191,199,1);
            font-family: PingFang SC;
            font-size: 12px;
            line-height: 18px;
            display: block;
          }
          strong{
            color: rgba(187,191,199,1);
            font-family: PingFang SC;
            font-weight: semibold;
            font-size: 16px;
            line-height: 24px;
            margin-top: 4px;
            display: block;
          }
        }
      }
    }
    &-right{
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      &-bto{
        width: 130px;
        height: 65px;
        border-radius: 8px;
        background: rgba(96,183,191,0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        p{
          &:nth-child(1){
            color: rgba(96,183,191,1);
            font-family: PingFang SC;
            font-weight: regular;
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 4px;
            text-align: center;
          }
          &:nth-child(2){
            color: rgba(96,183,191,1);
            font-family: PingFang SC;
            font-weight: bold;
            font-size: 18px;
            line-height: 27px;
          }
        }
  
      }
    }
    &.en{
      height: 323px!important;
      .Huigou-Wrap-right-bto{
        height: 83px;
      }
      .Huigou-Wrap-left-bto{
        margin-top: 18px;
      }
      .Huigou-Wrap-left P:nth-child(3){
        width: 70%;
      }
    }
  
  }
}
