@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-pledge__rules {
	grid-area: rule;
	padding: 16px;
	background-color: #1d2a43;
	border-radius: 16px;
	margin-top: -32px;
	border-radius: 0 0 16px 16px;
	.md({
    margin-top: 0;
    border-radius: 16px;
  });
	&__title {
		color: #6fa9fa;
		font-family: Montserrat-ExtraBold, sans-serif;
		font-size: 24px;
		line-height: 40px;
		font-weight: 600;
	}
	&__content {
		font-size: 14px;
		line-height: 14px;
		color: #9db1d8;
		list-style-type: auto;
		list-style-position: inside;
		line-height: 2;
	}
}
