@import "./assets/iconfont/iconfont.css";

.j9bc-root {
	--J9BC-bgc: #121e36;
	--J9BC-xs: 0px;
	--J9BC-sm: 576px;
	--J9BC-md: 768px;
	--J9BC-lg: 992px;
	--J9BC-xl: 1200px;
	font-size: 14px;
	color: #fff;
	background: var(--j9bc-background, var(--J9BC-bgc));
	font-family: "Montserrat", "Open Sans", "PingFang SC", "Microsoft YaHei", "Helvetica Neue", "Hiragino Sans GB", "WenQuanYi Micro Hei",
		Arial, sans-serif;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.j9bc-root ::-webkit-scrollbar {
	width: 4px;
}

.j9bc-root ::-webkit-scrollbar-thumb {
	border-radius: 8px;
	-webkit-box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
	background: #50607f;
	cursor: pointer;
}

.j9bc-root ::-webkit-scrollbar-track {
	border-radius: 0;
	background: var(--J9BC-bgc);
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

button,
input {
	outline: 0;
	border: 0;
	background: none;
}
ul {
	list-style: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
 input[type=number] {
    -moz-appearance:textfield;
}
