@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-lendingcom {
  margin-top: 16px;

  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &_main {
    border-radius: 25px 16px 16px 16px;
    background: rgba(40, 42, 47, 1);
    position: relative;
    display: flex;
    align-items: center;

    &_left {
      flex: 1;
      background-image: url('../../img/bg1.png');
      background-repeat: no-repeat;
      background-size: 100%;
      position: relative;
      height: 348px;
      border-radius: 16px;

      &_img {
        position: absolute;
        width: 432px;
        height: 322px;
        top: 26px;
        left: -20px;
        border-radius: 16px;
      }

      &_div {
        position: relative;
        margin: 32px 64px 32px 400px;

        &_title {
          color: rgba(224, 224, 224, 1);
          font-size: 14px;
        }

        &_input {
          width: 100%;
          height: 56px;
          border-radius: 8px;
          margin-top: 8px;

          .outline-input-new {
            border-radius: 8px;
            background: rgba(38, 38, 41, 1);
            border: 1px solid rgba(69, 69, 89, 1);
          }

          .loancom_after {
            display: flex;
            align-items: center;

            p {
              margin-right: 8px;
              color: rgba(255, 115, 0, 1);
              font-size: 12px;
              text-align: center;
              display: block;
              width: 40px;
              cursor: pointer;
            }

            img {
              width: 16px;
              height: 16px;
            }

            span {
              color: rgba(69, 69, 89, 1);
              margin-left: 4px;
              font-size: 14px;
              text-align: center;
            }
          }
        }

        &_del {
          color: rgba(136, 136, 136, 1);
          font-size: 12px;
          margin-top: 8px;
        }

        &_btn {
          opacity: 0.4000000059604645;
          border-radius: 8px;
          background: rgba(255, 116, 0, 1);
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          line-height: 50px;
          text-align: center;
          cursor: pointer;
          height: 50px;
          margin-top: 32px;
        }

        &_active {
          opacity: 1;
          background: rgba(255, 115, 0, 1);
        }
      }
    }

    &_right {
      height: 348px;
      width: 416px;
      padding: 32px;
      border-radius: 16px;
      background: rgba(58, 60, 65, 1);

      & &_li:first-child {
        margin-top: 0;
      }

      &_li {
        display: flex;
        align-items: center;
        height: 46px;
        border-bottom: 1px solid rgba(69, 82, 115, 0.15);
        margin-top: 8px;

        &_text {
          flex: 1;
          display: flex;
          align-items: center;

          &_title {
            color: rgba(255, 255, 255, 0.8);
            font-size: 14px;
          }

          &_num {
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            margin-left: 4px;
          }
        }

        &_btn {
          width: 76px;
          border-radius: 8px;
          background: rgba(255, 115, 0, 1);
          text-align: center;
          height: 34px;
          line-height: 34px;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          cursor: pointer;
        }

        &_btnfalse {
          width: 76px;
          border-radius: 8px;
          background: #ff7400;
          text-align: center;
          height: 34px;
          line-height: 34px;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          cursor: pointer;
          opacity: 0.40000001;
        }
      }
    }
  }


}

.j9bc-lendingcom_sub {
  border-radius: 16px;
  background: rgba(49, 49, 63, 1);

  .j9bc-lendingcom_section {
    position: relative;
    width: 343px;
    padding: 32px;

    .j9bc-lendingcom_section_close {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .j9bc-lendingcom_section_h3 {
      color: rgba(136, 136, 136, 1);
      font-size: 14px;
      line-height: 24px;
      text-align: center;
    }

    .j9bc-lendingcom_section_btnbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;

      .j9bc-lendingcom_section_cancel {
        color: rgba(255, 116, 0, 1);
        font-size: 16px;
        line-height: 44px;
        letter-spacing: 0px;
        text-align: center;
        width: 131px;
        border-radius: 10px;
        border: 1px solid rgba(255, 116, 0, 1);
        cursor: pointer;
      }

      .j9bc-lendingcom_section_confirm {
        cursor: pointer;
        border-radius: 10px;
        border: 1px solid rgba(255, 116, 0, 1);
        background: rgba(255, 116, 0, 1);
        width: 131px;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        line-height: 44px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

.j9bc-lendingcom2_sub {
  border-radius: 16px;
  background: rgba(49, 49, 63, 1);

  .j9bc-lendingcom2_section {
    position: relative;
    width: 343px;
    padding: 32px;
    h2{
      text-align:center;
    }
    .j9bc-lendingcom2_section_close {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .j9bc-lendingcom2_section_h3 {
      color: rgba(136, 136, 136, 1);
      font-size: 14px;
      line-height: 24px;
      text-align: center;
    }

    .j9bc-lendingcom2_btn {
      border-radius: 10px;
      background: rgba(255, 116, 0, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      margin-top: 16px;
      cursor: pointer;
    }

    .j9bc-lendingcom_section_btnbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;

      .j9bc-lendingcom_section_cancel {
        color: rgba(255, 116, 0, 1);
        font-size: 16px;
        line-height: 44px;
        letter-spacing: 0px;
        text-align: center;
        width: 131px;
        border-radius: 10px;
        border: 1px solid rgba(255, 116, 0, 1);
        cursor: pointer;
      }

      .j9bc-lendingcom_section_confirm {
        cursor: pointer;
        border-radius: 10px;
        border: 1px solid rgba(255, 116, 0, 1);
        background: rgba(255, 116, 0, 1);
        // width: 131px;
        width:100%;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        line-height: 44px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

.j9bc-lendingcom_sub,
.j9bc-lendingcom2_sub,
.j9bc-lendingcom2_sub {

  .j9bc-modal__header {
    display: none;
  }

  .j9bc-modal__footer {
    display: none;
  }

  .j9bc-modal__main {
    margin-top: 0 !important;
  }
}

@media screen and (max-width:960px) {
  .j9bc-lendingcom {
    margin-top: 16px;

    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }

    &_main {
      padding-top: 50px;
      border-radius: 25px 16px 16px 16px;
      background: rgba(40, 42, 47, 1);
      position: relative;
      display: block;
      align-items: center;

      &_left {
        flex: 1;
        background-image: none;
        background-repeat: no-repeat;
        background-size: 100%;
        position: relative;
        height: auto;
        border-radius: 16px;

        &_img {
          position: absolute;
          width: 432px;
          height: 322px;
          top: 26px;
          left: -20px;
          display: none;
          border-radius: 16px;
        }

        &_div {
          position: relative;
          margin: 0 !important;
          padding: 0 16px;

          &_title {
            color: rgba(224, 224, 224, 1);
            font-size: 14px;
            padding-top: 20px;
          }

          &_input {
            width: 100%;
            height: 56px;
            border-radius: 8px;
            margin-top: 8px;

            .outline-input-new {
              border-radius: 8px;
              background: rgba(38, 38, 41, 1);
              border: 1px solid rgba(69, 69, 89, 1);
            }

            .loancom_after {
              display: flex;
              align-items: center;

              p {
                margin-right: 8px;
                color: rgba(255, 115, 0, 1);
                font-size: 12px;
                text-align: center;
                display: block;
                width: 40px;
                cursor: pointer;
              }

              img {
                width: 16px;
                height: 16px;
              }

              span {
                color: rgba(69, 69, 89, 1);
                margin-left: 4px;
                font-size: 14px;
                text-align: center;
              }
            }
          }

          &_del {
            color: rgba(136, 136, 136, 1);
            font-size: 12px;
            margin-top: 8px;
          }

          &_btn {
            opacity: 0.4000000059604645;
            border-radius: 8px;
            background: rgba(255, 116, 0, 1);
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            line-height: 50px;
            text-align: center;
            cursor: pointer;
            height: 50px;
            margin: 24px auto;
          }

          &_active {
            opacity: 1;
            background: rgba(255, 115, 0, 1);
          }
        }
      }

      &_right {
        min-height: 262px;
        height: auto;
        width: auto;
        padding: 30px 16px;
        border-radius: 16px;
        background: rgba(58, 60, 65, 1);

        & &_li:first-child {
          margin-top: 0;
        }

        &_li {
          display: flex;
          align-items: center;
          height: 46px;
          border-bottom: 1px solid rgba(69, 82, 115, 0.15);
          margin-top: 8px;

          &_text {
            flex: 1;
            display: flex;
            align-items: center;

            &_title {
              color: rgba(255, 255, 255, 0.8);
              font-size: 14px;
            }

            &_num {
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
            }
          }

          &_btn {
            width: 76px;
            border-radius: 8px;
            background: rgba(255, 115, 0, 1);
            text-align: center;
            height: 34px;
            line-height: 34px;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (max-width:1300px) {
  .j9bc-lendingcom_main_left_img {
    display: none;
  }
  .j9bc-lendingcom_main_left_div{
    margin: 32px 64px 32px 64px;
  }
}