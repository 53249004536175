+b( qatar )
  background #0c2c65

  +e( top )
    display grid
    width 100%

    +e( mouse )
      z-index 3
      width 16px
      height 28px
      border 1px solid currentColor
      border-radius 16px
      color rgba( 142, 138, 139, 1 )
      text-align center
      line-height 0
      transition all 300ms
      position fixed
      left 50%
      margin-left -8px

      +ltmd()
        top 90vw
      +md(  )
         top 45vw
        
      &.scrolling
        opacity 0
        transform translateY( 100px )

      &:before
        display block
        content "\2024"
        font-size 24px
        line-height 0

        +md(  )
          animation j9bc-qatar-mouse-wheel 1000ms infinite linear alternate

    +e( mouse-arrow )
      position absolute
      top 110%
      left 50%
      margin-left -1px
      width 2px
      height 20px
      background currentColor
      color currentColor

      +ltmd(  )
        animation j9bc-qatar-mouse-arrow 2000ms infinite linear

      &:before
        position absolute
        top 100%
        left 50%
        margin-left -5px
        border 5px solid transparent
        border-top-color currentColor
        content ""

  +e( body )
    background #0c2c65 no-repeat top left / 100% auto
    padding-bottom 50px

    +ltmd(  )
      background-image url( "./img/background-h5.jpg" )

    +md(  )
      background-image url( "./img/background.jpg" )

  +e(toTop) 
    position fixed
    width 40px
    height 40px
    border-radius 50%
    z-index 20
    bottom 100px
    right 20px
    background-image url(./img/Top.png)
    background-size 100% 100%

  +e( main )
    margin 0 auto
    max-width 1200px

    +md(  )
      padding-top 50px

  +e( header )
    position relative
    display grid
    padding 0 16px

    +md(  )
      align-content center
      padding 0 24px
      padding-right 40%
      padding-left 15%

    +ltmd(  )
      place-items center center

    titlestyle =
      background-image linear-gradient( 180deg, rgba( 252, 254, 255, 1 ) 26%, rgba( 150, 235, 250, 1 ) 100% )
      background-clip text
      color transparent
      font-weight 600
      line-height 2

    +e( title )
      {titlestyle}
      font-size 18px
      font-weight normal

      +md(  )
        font-size 30px
      +ltmd()
        font-size 30px
        font-weight bold

    +e( subtitle )
      {titlestyle}
      color #88aba9
      font-size 16px
      margin-top -10px

      +en(  )
        display none

    +e( desc )
      margin-top 4px
      color #88aba9
      white-space pre-line
      font-size 12px
      line-height 1.5

      +md(  )
        margin-top 16px
        font-size 16px

  +e(wish)
    display flex
    flex-direction column
    justify-content center
    align-items center
    padding 60px 0px

    #ltmd()
      padding 30px 0px
    
    titlestyle =
      background-image linear-gradient( 180deg, rgba( 252, 254, 255, 1 ) 26%, rgba( 150, 235, 250, 1 ) 100% )
      background-clip text
      color transparent
      font-weight 600
      line-height 2

    +e(title) 
      {titlestyle}
      font-size 30px
      display flex
      justify-content center
      padding 30px 0px 0px
      +ltmd()
        font-weight bold
        font-size 24px
    +e(subtitle)
      font-size 12px
      letter-spacing 3px
      opacity 0.4

    +e(video) 
      margin-top 30px
      overflow hidden
      display flex
      justify-content center

      +md()
        width 800px
        height 450px
        border-radius 10px
      +ltmd()
        width 100%
        padding 0px 0px
        border-radius 5px
    +e(image)
      width 100%
      height 100%

      +md()
        width 800px
        height 450px
        border-radius 10px
      +ltmd()
        width 100%
        padding 0px 20px
        border-radius 5px


  +e(activities)
    display flex
    flex-direction column
    justify-content center
    align-items center
    padding 80px 0px

    +ltmd()
      padding 0px 20px

    +e(h5title) {
      position absolute
      top 0
      width 100%
      transform translateY(20px)
      display flex
      justify-content center
      font-size 16px
    }

    +e(bg) 
      display flex
      flex-direction column
      justify-content space-around
      align-items center
      padding-top 100px
      padding-bottom 60px
      position relative

      +e(mask)
        display flex
        align-items center
        justify-content center
        width 100%
        height 100%
        background #000
        position absolute
        top 0
        left 0
        background rgba(0, 0, 0, 0.3)
        font-size 16px

      +md()
        width 900px
        height 850px
        background url('./img/activitybg.png') no-repeat
        background-size 100% 100%

      +ltmd()
        background url('./img/activitybg_h5.png') no-repeat
        background-size 100% 100%
        width 100%
        height auto
        padding-top 80px

      +e(item)
        +ltmd()
          padding 0px 20px

        +e(title) 
          font-size 14px
          margin 10px 0px
          color #ddd
          +ltmd()
            font-size 12px
            display flex
            justify-content center

        +e(pic) 
          border-radius 10px 10px 0px 0px
          overflow hidden
          position relative
        
        +e(btnbg) 
          display flex
          justify-content center
          align-items center
          background-color #0d1c3d
          height 60px
          border-radius 0px 0px 10px 10px

        +e(img) 
          height 200px
          width 680px;
          
          +ltmd()
            width 100%
            height auto
        
        +e(btn) 
          height 40px
          width 350px
          display flex
          justify-content center
          align-items center
          background-image linear-gradient(to right, #cb7802 0%, #dca129 100%)
          background-size 100% 100%
          border-radius 5px
          font-size 14px
          letter-spacing 2px
          cursor pointer

          +ltmd() 
            max-width 260px

    +e(bg2) 
      width 900px
      height 400px
      background url('./img/activitybg2.png') no-repeat
      background-size 100% 100%
      margin-top 50px
      display flex
      align-items center
      padding 50px 30px 50px 30px

      +e(mask)
        display flex
        align-items center
        justify-content center
        width 100%
        height 100%
        background #000
        position absolute
        top 0
        left 0
        font-size 16px
        background rgba(0, 0, 0, 0.3)

      +md()
        width 900px
        height 400px
        background url('./img/activitybg2.png') no-repeat
        background-size 100% 100%
        justify-content space-around

      +ltmd()
        background url('./img/activitybg2_h5.png') no-repeat
        background-size 100% 100%
        width 100%
        height auto
        flex-direction column
        padding-top 20px
        padding-bottom 80px

      +e(item)
        +e(title) 
          font-size 14px
          margin 5px 0px
          color #ddd

          +ltmd() 
            font-size 12px
            display flex
            justify-content center

        +e(pic) 
          border-radius 10px 10px 0px 0px
          overflow hidden
          position relative
        
        +e(btnbg) 
          display flex
          justify-content center
          align-items center
          background-color #0d1c3d
          height 60px
          border-radius 0px 0px 10px 10px

        +e(img) 
          height 160px
          width 400px;

          +ltmd()
            width 100%
            height auto
            
        
        +e(btn) 
          height 40px
          width 300px
          display flex
          justify-content center
          align-items center
          background-image linear-gradient(to right, #cb7802 0%, #dca129 100%)
          background-size 100% 100%
          border-radius 5px
          font-size 14px
          letter-spacing 1px
          cursor pointer

          +ltmd() 
            max-width 260px

  +e( matches )
    padding 0px 15px
    -ms-overflow-style none
    scrollbar-width none
    &::-webkit-scrollbar
      display none

    margin-top 26px

    +e( title )
      color rgba( 84, 182, 253, 1 )
      text-align center
      font-weight 600
      font-size 18px
      line-height 1.5

      +md(  )
        font-size 30px
      +ltmd()
        font-weight bold
        font-size 24px

    +e( subtitle )
      text-align center
      font-size 12px
      line-height 1.5
      opacity 0.4
      letter-spacing 3px

      +md(  )
        font-size 16px

    +e( body )
      margin-top 32px
      padding 24px 16px
      border 1px solid rgba( 255, 255, 255, 0.04 )
      border-radius 8px
      background #184879;
      +md(  )
        padding 32px

  +e( groups )
    display grid
    align-items flex-start
    overflow auto hidden
    grid-template-columns auto auto auto auto auto
    gap 16px
    scroll-snap-type x mandatory

    &::-webkit-scrollbar
      display none

    +md(  )
      padding 20px 16px
      border 2px solid rgba( 255, 255, 255, 0.04 )
      border-radius 8px
      background #1b538e
      grid-template-columns 1fr 2fr 2fr 2fr 2fr

    +e( title )
      +ltmd(  )
        display none

  +e( "group" )
    scroll-snap-align start

    +e( title )
      padding-left 8px
      border-left 2px solid
      color rgba( 255, 255, 255, 1 )
      font-weight 500
      font-size 24px
      line-height 1


    +e( col )
      display grid
      gap 16px

    +e( country )
      min-width 170px
      display flex
      align-items center
      padding-left 4px
      height 38px
      border-radius 4px
      background rgba( 255, 255, 255, 0.1 )
      gap 4px

  +e( "round" )
    display grid
    overflow auto hidden
    margin-top 32px
    grid-template-columns repeat( 4, minmax( 176px, 1fr ) )
    gap 16px
    scroll-snap-type x mandatory

    &::-webkit-scrollbar
      display none

    +e( col )
      scroll-snap-align start

    +e( items )
      display grid
      gap 8px

    +e( item )
      display grid
      padding 0 8px
      height 106px
      border-radius 4px
      background rgba( 255, 255, 255, 0.1 )

    +e( cell )
      display flex
      align-items center

      &:first-child
        font-size 12px

      &>div:last-child
        margin-left auto

      &>img
        margin-right 4px

    +e( title )
      color rgba( 255, 255, 255, 0.6 )
      text-align center

      &.highlight
        color rgba( 255, 255, 255, 1 )

    +e( No )
      font-weight 600
      font-size 18px

      +md(  )
        font-size 24px

    +e( date )
      // font-weight 600
      font-size 14px
      line-height normal

      +md(  )
        font-size 24px

      &:before
      &:after
        content "-"

    +e( time )
      grid-column span 2

  
  +e(matchItem) 
    margin-bottom 10px
    +e(content)
      background #38629e
      padding 10px
      border-radius 5px
      min-width 180px
    +e(final) {
      background #1f4061
    }
    +e(finalTitle) 
      transform translateX(180px)
      width 220px
      display flex
      justify-content center
      font-size 16px
      margin-bottom 5px
    +e(theFinal)
      background #0486a8
      width 220px
      transform translateX(180px)

    +e(theThirdPlace)
      transform translateX(280px)
    +e(date-time)
      display flex
      justify-content space-between
      margin-bottom 5px
      font-size 13px
    +e(date)
      display flex
      align-items center
      font-size 13px
    +e(vline) 
      height 15px
      border-left 2px solid #fff7f2
      margin-right 5px
    +e(cell) 
      display flex
      margin-bottom 5px
      justify-content space-between
      align-items center
    +e(country) 
      margin-left 5px
    +e(cellleft)
      display flex
      align-items center
    +e(groupName)
      width 100%
      display flex
      justify-content center
      font-size 18px
    +e(roundName)
      width 100%
      display flex
      justify-content center
      font-size 14px
      margin-bottom 5px
    +e(hidden) 
      display none

  +e(finals)
    background #072b4f
    padding 40px
    display grid
    overflow auto hidden
    margin-top 32px
    grid-template-columns repeat( 1 )
    gap 16px
    scroll-snap-type x mandatory
    position relative
    border-radius 10px

    -ms-overflow-style none
    scrollbar-width none
    &::-webkit-scrollbar
      display none

    +e(semitop)
      transform translateX(35px)

    +e(thirdPlaceTitle)
      transform translate(250px, -10px)

    +e(top)
      display flex
      align-items center
      justify-content center

    +e(final__ul)
      margin-left -100px

    +e(semi__ul)
      margin-left -50px

    +e(bottom)
      position absolute
      bottom -40px
      left 0
      right 0
      display flex
      flex-direction column
      justify-content center
      align-items center

    +e(sepone)
      height 20px
      width 600px
      border 1px solid #666
      border-top none

    +e(septwo)
      height 20px
      width 1px
      border-left 1px solid #666

    +e(thirdPlace)
      position absolute
      right 100px
      bottom 60px

    +e(sepshort)
      height 20px
      width 260px
      border 1px solid #666
      border-top none
      position relative
      &:after
        display block
        content ''
        border-top 1px dashed #666
        border-right 1px dashed #666
        width 200px
        height 20px
        position absolute
        right -200px
        bottom -20px

    +e(finalTop)
      flex-direction column

    +e(hidden)
      display none    

    +e(finalTitle) 
      display flex
      margin-left 10px
      margin-top 0px
      font-size 16px
      align-items center
      margin-bottom 50px
      min-width 120px

    +e(vline) 
      margin-right 5px
      height 15px
      border-left 2px solid

    +e(col)
      display grid
      grid-template-columns auto auto auto auto
      gap 16px
      margin-bottom 40px
      position relative

    +e(finalCol) 
      gap 0px

    +e(place) 
      width 16px

  +e( struct )
    display grid
    overflow auto hidden
    margin-top 52px
    height 513px
    grid-template-columns repeat( 3, auto )
    scroll-snap-type x mandatory

    &::-webkit-scrollbar
      display none

    +md(  )
      justify-content flex-start
      margin-top 32px
      height 814px

    +e( "col" )
      display grid
      box-sizing content-box
      padding-right 16px
      width 176px
      scroll-snap-align start

      +md(  )
        padding-right 80px
        width 224px

      &:last-child
        align-content center
        padding-right 0
        gap 60px

        &>^[-2]
          +e( "group" )
            &:before
            &:after
              content none

    +e( title )
      margin-bottom 16px
      padding-left 8px
      border-left 2px solid
      white-space nowrap
      font-weight 500
      font-size 18px
      line-height 1.1

      +md(  )
        font-size 24px

    +e( "group" )
      position relative
      z-index 1
      display grid
      align-content space-around

      &:before
      &:after
        position absolute
        z-index -1
        width 8px
        border-style solid
        border-color rgba( 255, 255, 255, 0.3 )
        content ""

        +md(  )
          width 40px

      &:after
        right -16px
        bottom var( --j9bc-qatar-struct-bottom, 50% )
        border-width 1px 1px 0 0

        +md(  )
          right -80px

      &:before
        top 26%
        right -8px
        bottom 26%
        border-width 1px 1px 1px 0

        +md(  )
          right -40px

      &:nth-child(1):before
        top calc( 25% + 24px )
        bottom 23%

    +e( content )
      display grid
      padding 12px
      height 106px
      border-radius 4px
      background rgba( 255, 255, 255, 0.1 )
      font-size 12px
      pointer-events none
      gap 8px

      +md(  )
        height 148px
        font-size 14px
        gap 16px

    +e( cell )
      display flex
      align-items center
      padding 0 4px
      height 28px
      border-radius 8px
      background-color rgba( 255, 255, 255, 0.05 )

      &.winner
        background rgba( 255, 255, 255, 0.25 )

      +md(  )
        height 38px

    +e( flag )
      margin-right 4px
      width 20px

      +md(  )
        width auto

    +e( score )
      margin-left auto

    +e( time )
      margin-left auto

    +e( date-time )
      display flex

  +e( footer )
    display grid
    justify-content center
    overflow hidden
    background-color #0c2c65
    padding-bottom 40px

    +e(images)
      display flex
      justify-content center
      margin-top 40px
      
    +e(img_h5) 
      width 75px
      margin 0 -12px
      &:nth-child(2n-1)
        margin-top -20px

    +e( body )
      display grid
      padding 60px 0
      width 1920px
      grid-template-columns 682px 556px 682px

    +e( left )
      display flex
      transform translateX( 278px )

      ^[-1]
        +e( right )
          &
          ^[2]
            position relative
            z-index 2
            background-color #0c2c65

            &.appear
              transition transform 1000ms 500ms
              transform translateX( 0 )

              &>img
                transition transform 1000ms 1500ms

                &:nth-child(2)
                  transform translateY( 30px )

                &:nth-child(3)
                  transform translateY( -30px )

      &>img
        transition transform 1000ms

        &:nth-child(2)
          margin-left -90px

        &:nth-child(3)
          margin-left -80px

    +e( right )
      display flex
      transform translateX( -278px )

      &>img
        &:nth-child(1)
          margin-right -90px

        &:nth-child(2)
          margin-right -80px

    +e( "join" )
      display grid
      align-content center
      place-items center center
      gap 40px

    +e( "join-button" )
      min-width 130px
      height 40px
      border-radius 8px
      background linear-gradient( 90deg, rgba( 227, 190, 141, 1 ) 0, rgba( 215, 158, 80, 1 ) 100% )
      color rgba( 52, 52, 52, 1 )
      font-weight 600
      font-size 16px

    +e( copyright )
      color rgba( 166, 166, 166, 1 )
      text-align center
      font-size 12px
      line-height 100px

  +e(comments)
    display flex
    flex-direction column
    justify-content center
    align-items center
    padding 60px 0px
    width 100%
    background-size 100% 100%
    background-image url('./img/commentbg.jpg')
    position relative



    +ltmd()
      align-items flex-start
      background-image url('./img/comments_bg_h5.jpg')
      comments_bg_h5
      padding-top 90px

    +e(allItems) 
      width 100%
      margin-top 30px

    +e(videos)
      display flex
      flex-direction column
      justify-content center
      align-items center
      padding 20px
      -ms-overflow-style none
      scrollbar-width none

      &::-webkit-scrollbar
        display none

      +ltmd()
        align-items flex-start
        overflow auto hidden
        width 100%
        padding-right 20px

      +e(items)
        +md() 
          width 880px
          display flex
          justify-content center
          gap 20px
        +ltmd()
          display: flex
          gap: 20px
          padding-right 5px
          width 100%

      +e(item)
        width 190px
        +ltmd()
          scroll-snap-align: start;

      +e(src)
        width 190px
        height 240px
        position relative
          
      +e(mask)
        display flex
        align-items center
        justify-content center
        width 100%
        height 100%
        background #000
        position absolute
        font-size 16px
        top 0
        left 0
        background rgba(0, 0, 0, 0)
        &:hover 
          background rgba(0, 0, 0, 0.3)



      +e(subtitle) 
        width 190px
        height 40px
        display flex
        padding 0px 10px
        align-items center
        background #113d6e
        font-size 12px

      titlestyle =
        background-image linear-gradient( 180deg, rgba( 252, 254, 255, 1 ) 26%, rgba( 150, 235, 250, 1 ) 100% )
        background-clip text
        color transparent
        font-weight 600
        line-height 2

      +e(title)
        {titlestyle}
        font-size 30px
        display flex
        justify-content center
        padding 30px 0px 0px        

        +ltmd()
          font-weight bold
          font-size 24px
          position absolute
          width 100vw
          display flex
          justify-content center
          top 10px
          left 0px


      +e(subtitle2)
        font-size 12px
        letter-spacing 3px
        opacity 0.4

        
        +ltmd() 
          position absolute
          width 100%
          display flex
          justify-content center
          top 80px
          left 0px

    +e(popvideo)
      background rgba(0, 0, 0, 0)
      width 100%
      height 100vh
      position fixed
      z-index 12000
      left 0
      top 0
      display flex
      align-items center
      justify-content center
      transform scale(0)

    +e(showPopbg)
      background rgba(0, 0, 0, 0)
      animation j9bc-qatar-popvideo ease-in-out 0.6s forwards

    +e(showPop)
      animation j9bc-qatar-popvideo ease-in-out 0.6s forwards

    +e(closeButton) 
      width 30px
      height 30px
      transform translate(-20px, -360px)
      border 3px solid #eee
      border-radius 50%
      background #767676
      color #eee
      cursor pointer
      +ltmd() 
        display none
      &:hover 
        background #4f92eb

    +e(video) 
      width 1280px
      height 720px
      transform scale(0)

      +ltmd() 
        width 100%
        height auto
      
      
@keyframes j9bc-qatar-popvideo 
  0% 
    transform scale(0)
  80%
    transform scale(1.02)
  100%
    transform scale(1)
    background rgba(0, 0, 0, 0.5)


@keyframes j9bc-qatar-mouse-arrow
  0%
  50%
    height 0

  100%
    height 20px

@keyframes j9bc-qatar-mouse-wheel
  0%
    transform translateY( 0 )

  100%
    transform translateY( 10px )

.j9bc-qatar-animate-block
  +md(  )
    opacity 0
    transition transform 1000ms, opacity 1000ms
    transform translateY( 100px )

    &.appear
      opacity 1
      transform translateY( 0 )

.hidden 
  display hidden

