@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-pledge__faq {
	display: grid;
	gap: 16px;
	align-items: flex-start;
	grid-area: faq;
	.md({
    grid-template-columns: repeat(2,1fr)
  });

	&__title {
		font-family: Montserrat-ExtraBold, sans-serif;
		font-size: 24px;
		line-height: 40px;
		font-weight: 600;
		grid-column: span 2;
	}

	&__items {
		display: grid;
		gap: 16px;
	}
	&__item {
		border-radius: 16px;
		background-color: #25324b;
	}
	&__q {
		padding: 0 16px;
		font-size: 14px;
		height: 72px;
		cursor: pointer;
		display: flex;
		color: #fff;
		align-items: center;
		&::after {
			content: "";
			width: 10px;
			height: 10px;
			border: solid currentColor;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
			margin-left: auto;
			color: var(--arrow-color, #6fa9fa);
			transition: all 0.2s ease-in-out;
			flex-shrink: 0;
		}
	}
	&__animate {
		transition: height 200ms linear;
	}
	&__a {
		padding: 0 16px 24px 16px;
		align-items: center;
		font-size: 14px;
		line-height: 28px;
		font-size: 14px;
		color: #99acd0;
	}

	&__item.active &__q::after {
		transform: rotate(-135deg);
	}
}
