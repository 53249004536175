@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.tips_des {
  font-size: 14px;
  margin-top: 24px;
  // margin-left: 2px;
  text-align: left;
  color: #FF4141 !important;
}

.outline-input,
.outline-input-new {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 16px;

  input {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    color: #FFFFFF;
    font-size: 16px;
    outline: none;
    border: none;
    box-shadow: none;
  }
}

.outline-input {
  height: 56px;

  input {

    &::-webkit-input-placeholder {
      color: transparent;
    }

    &:-moz-placeholder,
    &::-moz-placeholder {
      color: transparent;
    }

    &:-ms-input-placeholder {
      color: transparent;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-transition-delay: 999999s;
      -webkit-transition: color 999999s ease-out, background-color 999999s ease-out;
    }
  }

  label {
    pointer-events: none;
    position: absolute;
    color: rgba(255, 255, 255, .6);
    left: 16px;
    transform-origin: top left;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  }

  fieldset {
    position: absolute;
    bottom: 0;
    left: -1px;
    right: -1px;
    top: 0;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, .4);
    pointer-events: none;
    color: rgba(255, 255, 255, .6);

    legend {
      display: block;
      height: 1px;
      visibility: hidden;
      max-width: 0.01px;
      transition: max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    }
  }

  &-before {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 13px;
  }

  &-after {
    display: flex;
    align-items: center;

    & > button,
    & > div {
      margin-left: 5px;
    }

    .clear-btn {
      // visibility: hidden;
      transition: visibility .1s ease-in-out;

      i {
        opacity: 1;
        border-radius: 100%;
        color: rgba(80,96,127,1);
        transition: opacity 0.3s;
        transform: scale(.6);
        transform-origin: center;
        font-size: 30px;
        &:hover {
          opacity: .8;
        }
      }
    }

    i {
      color: rgba(255, 255, 255, .6);
    }
  }

  input:focus,
  input:required:valid {
    & + label {
      top: 0;
      left: 15px !important;
      transform: translate(5px, -50%) scale(.85);

      & + fieldset {
        legend {
          font-size: .85em;
          max-width: 100%;
        }

        .placeholder-legend {
          padding: 0 5px;
        }
      }
    }
  }

  input:focus {
    & ~ .outline-input-after button {
      visibility: visible;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .eye-icon i {
    width: 24px;
    height: 24px;
    font-size: 24px;
  }
}

//新版input样式
.outline-input-new {
  padding: 0 16px;
  border-radius: 12px;
  border: 2px solid #24314A;
  height: 54px;
  transition: all .2s ease-in-out;

  fieldset, label {
    display: none;;
  }

  &_focus {
    border: 2px solid #657697;
  }

  input {
    font-size: 14px;
    color: rgba(157, 177, 216, 0.7);
    font-family: "Montserrat-SemiBold", sans-serif;

    &::placeholder {
      font-size: 14px;
      color: #50607F;
    }
    &:focus {
      color: #9DB1D8;
    }
  }

  .eye-icon {
    border-radius: 8px;
    background-color: #24314A;
  }
}

@media (any-hover: hover) {
  .clear-btn {
    i:hover {
      opacity: .5;
    }
  }
}