@xs: 0px;
@sm: 576px;
@md: 768px;
@lg: 992px;
@xl: 1200px;

.response(@width,@rules) {
	@media screen and (min-width: @width) {
		@rules();
	}
}

.lt(@width,@rules) {
	@media screen and (max-width: (@width - 1)) {
		@rules();
	}
}

.sm(@rules) {
	.response(@sm,@rules);
}

.ltmd(@rules) {
	.lt(@md,@rules);
}
.md(@rules) {
	.response(@md,@rules);
}

.lg(@rules) {
	.response(@lg,@rules);
}

.xl(@rules) {
	.response(@xl,@rules);
}

.en(@rules) {
	&:lang(en) {
		@rules();
	}
}

.zh(@rules) {
	&:lang(zh) {
		@rules();
	}
}




.inner(@rules, @second, @third:false) {
	each(@rules, {
    @name:@value[name];
    @val:@value[values];
    @purestyle:@value[style];
    @var:~"--@{name}";
    @space:@value[space];
    
    & when (@purestyle = true) {
      
      & when (@third = false) {
        @prefix : ~"@{second}-@{name}";
        .hx-@{prefix} when not(@second = zh) and not (@second = en) {
          @{var}:~"var(--@{prefix})"
        }
  
        .hx-@{prefix}:lang(en) when (@second = en) {
          @{var}:~"var(--@{prefix})"
        }
  
        .hx-@{prefix}:lang(zh) when (@second = zh) {
          @{var}:~"var(--@{prefix})"
        }
      }
  
      & when not(@third = false) {
        @prefix: ~"@{second}-@{third}-@{name}";
        .hx-@{prefix} when not(@second = zh) and not (@second = en) {
          @{var}:~"var(--@{prefix}})"
        }
  
        .hx-@{prefix}:lang(en) when (@second = en) {
          @{var}:~"var(--@{prefix})"
        }
  
        .hx-@{prefix}:lang(zh) when (@second = zh) {
          @{var}:~"var(--@{prefix})"
        }
      }
    }

    

    & when not (@val=false) {
      each(@val,.( @k , @v )  {
        @suffix: if(@space,~"@{k}x",@k);
        @cssName: if(@third = false,~"@{second}-@{name}--@{suffix}",~"@{second}-@{third}-@{name}--@{suffix}");
        @cssValue: if(@space,@k * 8px,@k);
        .hx-@{cssName} when not(@second = zh) and not (@second = en) {
          @{var}:@cssValue;
        }
  
        .hx-@{cssName}:lang(en) when (@second = en) {
          @{var}:@cssValue;
        }
  
        .hx-@{cssName}:lang(zh) when (@second = zh) {
          @{var}:@cssValue;
        }
      });
    }

  });
}

.gen(@rules) {
	.inner(@rules,xs);
	.inner(@rules,zh);
	.inner(@rules,en);
	.inner(@rules,zh,xs);
	.inner(@rules,en,xs);
	.md({
	  .inner(@rules,md);
    .inner(@rules,zh,md);
    .inner(@rules,en,md);
	});
}
