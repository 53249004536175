@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-lendingcom {
  &_record {
    border-radius: 16px;
    background: rgba(40, 42, 47, 1);
    margin-top: 32px;
    &_box{
      display: flex;
      align-items: center;
    }
    &_title {
      color: rgba(255, 255, 255, 1);
      line-height: 84px;
      margin-left: 24px;
      font-weight: 600;
      font-size: 18px;
      cursor: pointer;
      &_active{
        color: rgba(136, 136, 136, 1);
      }
    }

    &_main {
      padding: 0 24px 24px 24px;

      &_title {
        height: 56px;
        display: flex;
        align-items: center;

        &_span {
          width: 25%;
          text-align: center;
          color: rgba(136, 136, 136, 1);
          font-size: 16px;
          line-height: 24px;
        }
      }

      &_del {
        // overflow-y: scroll;
        // height: 150px;
        // max-height: 392px;
        position: relative;
        // min-height: 150px;
        &::-webkit-scrollbar {
          /*滚动条整体样式*/
          width: 5px;
          /*高宽分别对应横竖滚动条的尺寸*/
          height: 1px;
        }

        &::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 10px;
          box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
          background: rgb(255, 116, 0);
        }

        &::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          background: #737373;
        }

        &_li {
          height: 56px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #454559;

          &_span {
            width: 25%;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            line-height: 24px;
            text-align: center;
          }
        }

      }
      &_delnone,&_delh5none{
        min-height: 200px;
        .j9bc-locker__overlay{
          background-color:transparent;
        }
      }
      .j9bc-lendingcom_record_main_pagediv {
        display: flex;
        justify-content: center;
      }

      .j9bc-lendingcom-pagination {
        margin: 32px auto 0;
        align-items: end;
        margin-top: 32px;
        flex: 1;
        max-width: 100%;
        display: flex;
        justify-content: center;

        .rc-pagination-prev {
          margin-right: 4px;
        }

        .rc-pagination-prev,
        .rc-pagination-next {
          width: 30px;
          height: 30px;
          line-height: 30px;
          .rc-pagination-item-link {
            color: #878787;
            font-size: 20px;
            border-radius: 8px;
            background: rgba(136, 136, 136, 0.16);
            border: 0;
          }
        }

        .rc-pagination-item a {

          opacity: 0.44999998807907104;
          color: rgba(135, 135, 135, 1);
          width: 30px;
          height: 30px;
        }

        .rc-pagination-item {
          font-weight: 600;
          background: rgba(136, 136, 136, 0.16) !important;
          border: none;
          border-radius: 8px;
          width: 30px;
          height: 30px;
          line-height: 30px;
          &-active {
            background: rgb(255, 116, 0) !important;
            color: #fff !important;
            border-color: transparent !important;

            a {

              color: rgba(255, 255, 255, 1);
            }
          }
        }
      }
    }
  }
}









@media screen and (max-width:960px) {
  .j9bc-lendingcom {
    &_record {
      border-radius: 16px;
      background: rgba(40, 42, 47, 1);
      margin-top: 32px;

      &_title {
        color: rgba(255, 255, 255, 1);
        font-size: 24px;
        line-height: 74px;
        margin-left: 24px;
      }

      &_main {
        padding: 0 16px 16px 16px;

        &_title {
          height: 46px;
          display: flex;
          align-items: center;

          &_span {
            width: 33%;
            text-align: center;
            color: rgba(136, 136, 136, 1);
            font-size: 16px;
            line-height: 24px;
          }

          & &_span:nth-child(4) {
            display: none;
          }

          & &_span:nth-child(5) {
            display: none;
          }
        }

        &_del {
          &::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 5px;
            /*高宽分别对应横竖滚动条的尺寸*/
            height: 1px;
          }

          &::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 10px;
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
            background: rgb(255, 116, 0);
          }

          &::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            background: #737373;
          }

          &_li {
            height: 46px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #454559;

            & &_span:nth-child(4) {
              display: none;
            }

            & &_span:nth-child(5) {
              display: none;
            }

            &_span {
              width: 33%;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              line-height: 24px;
              text-align: center;
            }
          }

        }

        &_delh5 {
          // height: 323px;
          // max-height: 323px;
        }

        .j9bc-lendingcom_record_main_pagediv {
          display: flex;
          justify-content: center;
        }

        .j9bc-lendingcom-pagination {
          margin: 32px auto 0;
          align-items: end;
          margin-top: 32px;
          flex: 1;
          max-width: 100%;
          display: flex;
          justify-content: center;

          .rc-pagination-prev {
            margin-right: 4px;
          }

          .rc-pagination-prev,
          .rc-pagination-next {
            width: 32px;
            height: 32px;

            .rc-pagination-item-link {
              color: #878787;
              font-size: 20px;
              border-radius: 8px;
              background: rgba(136, 136, 136, 0.16);
              border: 0;
            }
          }

          .rc-pagination-item a {

            opacity: 0.44999998807907104;
            color: rgba(135, 135, 135, 1);
          }

          .rc-pagination-item {
            font-weight: 600;
            background: rgba(136, 136, 136, 0.16) !important;
            border: none;
            border-radius: 8px;

            &-active {
              background: rgb(255, 116, 0) !important;
              color: #fff !important;
              border-color: transparent !important;

              a {
                color: rgba(255, 255, 255, 1);
                width: 30px;
                height: 30px;
              }
            }
          }
        }
      }
    }
  }
}