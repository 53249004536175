@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-loannav {
  &_navlist {
    display: flex;
    align-items: center;
    margin-top: 32px;

    &_li {
      font-size: 24px;
      cursor: pointer;

      &_text {
        color: rgba(255, 255, 255, 1);
        line-height: 40px;
      }

      &_line {
        width: 30px;
        height: 4px;
        border-radius: 4px;
        background: rgba(255, 116, 0, 1);
        margin: auto;
      }

      &:first-child {
        margin-right: 32px;
      }
    }
  }
}




@media screen and (max-width:960px) {
  .j9bc-loannav {
    position: relative;
    &_navlist {
      display: flex;
      align-items: center;
      margin-top: 0;
      position: absolute;
      z-index: 1;
      left: 16px;
      top: 24px;
      &_li {
        font-size: 20px;
        cursor: pointer;

        &_text {
          color: rgba(255, 255, 255, 1);
          line-height: 30px;
        }

        &_line {
          width: 25px;
          height: 4px;
          border-radius: 4px;
          background: rgba(255, 116, 0, 1);
          margin: auto;
        }

        &:first-child {
          margin-right: 32px;
        }
      }
    }
  }
}