@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-swaprule {
  // position: absolute;
  // bottom: -150px;
  border-radius: 16px;
  background: #1D2A43;
  color: #7789AB;
  font-family: PingFang SC;
  font-weight: semibold;
  font-size: 14px;
  width: 100%;
  padding: 24px;
  z-index: 1;
  margin: 24px 0;

  & &_swaprulebeside:first-child {
    margin-top: 0;
  }

  &_swaprulebeside {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    &_sb_title {
      display: flex;
      align-items: center;

      &_span {
        color: #7789AB;
        font-family: PingFang SC;
        font-weight: semibold;
        font-size: 14px;
        line-height: 22px;
        font-style: normal;
      }

      &_em {
        margin-left: 8px;
        background: #7789AB;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        position: relative;

        &_span {
          font-size: 12px;
          color: #1D2A43;
          line-height: 14px;
          height: 14px;
          width: 14px;
          display: inline-block;
          text-align: center;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &:hover{
          .j9bc-swaprule_swaprulebeside_sb_title_em_rulediv{
            display: block;
          }
        }
        &_rulediv {
          position: absolute;
          top: calc(-10px - 76px) !important;
          left: -22px;
          z-index: 1;
          display: none;
          &_ruledivmain{
            width: 246px;
            border-radius: 8px;
            background: #7789AB;
            color: #FFFFFF;
            font-family: PingFang SC;
            font-weight: regular;
            font-size: 14px;
            line-height: 22px;
            padding: 16px;
            font-style: normal;
  
          }
          &_rulej{
            width: 0;
            height: 0;
            margin-left: 20px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid #7789AB;
          }
        }
      }
    }

    &_sb_del {
      color: #7789AB;
      font-family: PingFang SC;
      font-weight: semibold;
      font-size: 14px;
      line-height: 22px;
    }
  }
  & .j9bc-swaprule_swaprulebeside:last-child{
    .j9bc-swaprule_swaprulebeside_sb_title  .j9bc-swaprule_swaprulebeside_sb_title_em .j9bc-swaprule_swaprulebeside_sb_title_em_rulediv{
      top: calc(-10px - 120px);
    }
  }
}
@media screen and (max-width: 768px) {
  .j9bc-swaprule{
    margin-top: 16px;
    margin-bottom: 16px;
  }
}