+b( fiba )
  background #0c2c65

  +e( top )
    display grid
    width 100%

    +e( mouse )
      z-index 3
      width 16px
      height 28px
      border 1px solid currentColor
      border-radius 16px
      color rgba( 142, 138, 139, 1 )
      text-align center
      line-height 0
      transition all 300ms
      position fixed
      left 50%
      margin-left -8px

      +ltmd()
        top 90vw
      +md(  )
         top 45vw
        

      &.scrolling
        opacity 0
        transform translateY( 100px )

      &:before
        display block
        content "\2024"
        font-size 24px
        line-height 0

        +md(  )
          animation j9bc-fiba-mouse-wheel 1000ms infinite linear alternate

    +e( mouse-arrow )
      position absolute
      top 110%
      left 50%
      margin-left -1px
      width 2px
      height 20px
      background currentColor
      color currentColor

      +ltmd(  )
        animation j9bc-fiba-mouse-arrow 2000ms infinite linear

      &:before
        position absolute
        top 100%
        left 50%
        margin-left -5px
        border 5px solid transparent
        border-top-color currentColor
        content ""

  +e( body )
    background #0c2c65 no-repeat top left / 100% auto

    +ltmd(  )
      background-image url( "./img/h5-background.jpg" )

    +md(  )
      background-image url( "./img/background.jpg" )

  +e( main )
    margin 0 auto
    max-width 1200px

    +md(  )
      padding-top 100px

  +e( header )
    position relative
    display grid
    padding 0 16px

    +md(  )
      align-content center
      padding 0 24px
      padding-right 535px
      height 613px
      background url( "./img/people.png" ) no-repeat right top

    +ltmd(  )
      place-items center center

    titlestyle =
      background-image linear-gradient( 180deg, rgba( 252, 254, 255, 1 ) 26%, rgba( 150, 235, 250, 1 ) 100% )
      background-clip text
      color transparent
      font-weight 600
      line-height 2

    +e( title )
      {titlestyle}
      font-size 18px

      +md(  )
        font-size 30px

    +e( subtitle )
      {titlestyle}
      font-size 16px

      +en(  )
        display none

    +e( desc )
      margin-top 4px
      color rgba( 255, 255, 255, 1 )
      white-space pre-line
      font-size 12px
      line-height 2

      +md(  )
        margin-top 16px
        font-size 18px

  +e( matches )
    margin-top 26px

    +e( title )
      color rgba( 84, 182, 253, 1 )
      text-align center
      font-weight 600
      font-size 18px
      line-height 1.5

      +md(  )
        font-size 30px

    +e( subtitle )
      color rgba( 84, 182, 253, 1 )
      text-align center
      font-weight 600
      font-size 12px
      line-height 1.5
      opacity 0.4

      +md(  )
        font-size 16px

    +e( body )
      margin-top 32px
      padding 24px 16px
      border 1px solid rgba( 255, 255, 255, 0.04 )
      border-radius 8px
      background linear-gradient( -151.2deg, rgba( 19, 110, 195, 0.3 ) 0, rgba( 63, 137, 211, 0.1 ) 100% )

      +md(  )
        padding 32px

  +e( groups )
    display grid
    align-items center
    overflow auto hidden
    height 228px
    grid-template-columns repeat( 4, 224px )
    gap 16px
    scroll-snap-type x mandatory

    &::-webkit-scrollbar
      display none

    +md(  )
      padding 0 16px
      border 2px solid rgba( 255, 255, 255, 0.04 )
      border-radius 8px
      background linear-gradient( -151.2deg, rgba( 19, 110, 195, 0.3 ) 0, rgba( 63, 137, 211, 0.5 ) 100% ), linear-gradient( 180deg, rgba( 255, 255, 255, 0.05 ) 5%, rgba( 255, 255, 255, 0.2 ) 100% )
      grid-template-columns 135px repeat( 4, 1fr )

    +e( title )
      +ltmd(  )
        display none

  +e( "group" )
    scroll-snap-align start

    +e( title )
      padding-left 8px
      border-left 2px solid
      color rgba( 255, 255, 255, 1 )
      font-weight 500
      font-size 24px
      line-height 1

    +e( col )
      display grid
      gap 16px

    +e( country )
      min-width 170px
      display flex
      align-items center
      padding-left 4px
      height 38px
      border-radius 4px
      background rgba( 255, 255, 255, 0.1 )
      gap 4px

  +e( "round" )
    display grid
    overflow auto hidden
    margin-top 32px
    grid-template-columns repeat( 6, minmax( 176px, 1fr ) )
    gap 16px
    scroll-snap-type x mandatory

    &::-webkit-scrollbar
      display none

    +e( col )
      scroll-snap-align start

    +e( items )
      display grid
      gap 8px

    +e( item )
      display grid
      padding 0 8px
      height 106px
      border-radius 4px
      background rgba( 255, 255, 255, 0.1 )

    +e( cell )
      display flex
      align-items center

      &:first-child
        font-size 12px

      &>div:last-child
        margin-left auto

      &>img
        margin-right 4px

    +e( title )
      color rgba( 255, 255, 255, 0.6 )
      text-align center

      &.highlight
        color rgba( 255, 255, 255, 1 )

    +e( No )
      font-weight 600
      font-size 18px

      +md(  )
        font-size 24px

    +e( date )
      // font-weight 600
      font-size 14px
      line-height normal

      +md(  )
        font-size 24px

      &:before
      &:after
        content "-"

    +e( time )
      grid-column span 2

  +e( struct )
    display grid
    overflow auto hidden
    margin-top 52px
    height 513px
    grid-template-columns repeat( 3, auto )
    scroll-snap-type x mandatory

    &::-webkit-scrollbar
      display none

    +md(  )
      justify-content flex-start
      margin-top 32px
      height 814px

    +e( "col" )
      display grid
      box-sizing content-box
      padding-right 16px
      width 176px
      scroll-snap-align start

      +md(  )
        padding-right 80px
        width 224px

      &:last-child
        align-content center
        padding-right 0
        gap 60px

        &>^[-2]
          +e( "group" )
            &:before
            &:after
              content none

    +e( title )
      margin-bottom 16px
      padding-left 8px
      border-left 2px solid
      white-space nowrap
      font-weight 500
      font-size 18px
      line-height 1.1

      +md(  )
        font-size 24px

    +e( "group" )
      position relative
      z-index 1
      display grid
      align-content space-around

      &:before
      &:after
        position absolute
        z-index -1
        width 8px
        border-style solid
        border-color rgba( 255, 255, 255, 0.3 )
        content ""

        +md(  )
          width 40px

      &:after
        right -16px
        bottom var( --j9bc-fiba-struct-bottom, 50% )
        border-width 1px 1px 0 0

        +md(  )
          right -80px

      &:before
        top 26%
        right -8px
        bottom 26%
        border-width 1px 1px 1px 0

        +md(  )
          right -40px

      &:nth-child(1):before
        top calc( 25% + 24px )
        bottom 23%

    +e( content )
      display grid
      padding 12px
      height 106px
      border-radius 4px
      background rgba( 255, 255, 255, 0.1 )
      font-size 12px
      pointer-events none
      gap 8px

      +md(  )
        height 148px
        font-size 14px
        gap 16px

    +e( cell )
      display flex
      align-items center
      padding 0 4px
      height 28px
      border-radius 8px
      background-color rgba( 255, 255, 255, 0.05 )

      &.winner
        background rgba( 255, 255, 255, 0.25 )

      +md(  )
        height 38px

    +e( flag )
      margin-right 4px
      width 20px

      +md(  )
        width auto

    +e( score )
      margin-left auto

    +e( time )
      margin-left auto

    +e( date-time )
      display flex

  +e( swiper )
    margin-top 60px
    padding 0 16px

    +md(  )
      margin-top 136px
      padding 31px

    +e( title )
      color rgba( 84, 182, 253, 1 )
      text-align center
      font-weight 600
      font-size 18px
      line-height 1.5

      +md(  )
        font-size 38px

    +e( desc )
      color rgba( 84, 182, 253, 1 )
      text-align center
      // font-weight: medium;
      font-size 12px
      line-height 1.5
      opacity 0.4

      +md(  )
        letter-spacing 20px
        font-size 16px

    +e( body )
      display grid
      align-items center
      margin-top 32px
      grid-template-columns auto minmax( 0, 1fr ) auto
      gap 12px

      +md(  )
        gap 32px

    +e( case )
      height 131.338px

      +md(  )
        height 500px

    +e( img )
      width 100%
      height 100%
      border-radius 8px
      user-select none
      object-fit cover

    .swiper-pagination
      position static !important
      margin-top 36px

      +md(  )
        margin-top 60px

    .swiper-pagination-bullet
      --swiper-pagination-bullet-inactive-color rgba( 93, 90, 89, 1 )

    svg
      +ltmd(  )
        width 30px

  +e( bet )
    position relative
    margin-top 38px
    padding 0 16px

    +en(  )
      display none

    +md(  )
      margin-top 66px
      padding 0 32px

    +e( nav )
      position relative

    +e( text )
      position absolute
      top 0
      right 0
      bottom 0
      left 0
      z-index 2
      display grid
      background-color rgba( 0, 0, 0, 0.5 )
      color rgba( 255, 255, 255, 0.8 )
      font-size 32px
      opacity 0
      transition opacity 300ms
      place-items center center

      +md(  )
        &:hover
          opacity 1

    +e( title )
      margin-top 16px
      // font-weight 600
      font-size 18px
      line-height 32px

      +md(  )
        font-size 24px

    +e( desc )
      margin-top 8px
      padding-bottom 16px
      border-bottom 1px solid rgba( 84, 182, 253, 1 )
      color rgba( 255, 255, 255, 0.8 )
      font-size 14px
      line-height 22px

    +e( img )
      height 101.75264084507043px
      border-radius 16px
      object-fit cover

      +md(  )
        height 337px

  +e( footer )
    display grid
    justify-content center
    overflow hidden
    background-color #0c2c65

    +e( body )
      display grid
      padding 60px 0
      width 1920px
      grid-template-columns 682px 556px 682px

    +e( left )
      display flex
      transform translateX( 278px )

      ^[-1]
        +e( right )
          &
          ^[2]
            position relative
            z-index 2
            background-color #0c2c65

            &.appear
              transition transform 1000ms 500ms
              transform translateX( 0 )

              &>img
                transition transform 1000ms 1500ms

                &:nth-child(2)
                  transform translateY( 30px )

                &:nth-child(3)
                  transform translateY( -30px )

      &>img
        transition transform 1000ms

        &:nth-child(2)
          margin-left -90px

        &:nth-child(3)
          margin-left -80px

    +e( right )
      display flex
      transform translateX( -278px )

      &>img
        &:nth-child(1)
          margin-right -90px

        &:nth-child(2)
          margin-right -80px

    +e( "join" )
      display grid
      align-content center
      place-items center center
      gap 40px

    +e( "join-button" )
      min-width 130px
      height 40px
      border-radius 8px
      background linear-gradient( 90deg, rgba( 227, 190, 141, 1 ) 0, rgba( 215, 158, 80, 1 ) 100% )
      color rgba( 52, 52, 52, 1 )
      font-weight 600
      font-size 16px

    +e( copyright )
      color rgba( 166, 166, 166, 1 )
      text-align center
      font-size 12px
      line-height 100px

@keyframes j9bc-fiba-mouse-arrow
  0%
  50%
    height 0

  100%
    height 20px

@keyframes j9bc-fiba-mouse-wheel
  0%
    transform translateY( 0 )

  100%
    transform translateY( 10px )

.j9bc-fiba-animate-block
  +md(  )
    opacity 0
    transition transform 1000ms, opacity 1000ms
    transform translateY( 100px )

    &.appear
      opacity 1
      transform translateY( 0 )
