@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-after-open {
	overflow-y: hidden;
	position: relative;
	height: 100%;
	.j9bc-root {
		padding-right: var(--j9bc-body-scroller-bar-width, 0);
		filter: blur(6px);
	}
}
.j9bc-modal {
	&__portal {
		font-family: "Montserrat", "Open Sans", "PingFang SC", "Microsoft YaHei", "Helvetica Neue", "Hiragino Sans GB",
			"WenQuanYi Micro Hei", Arial, sans-serif;
	}
	&__overlay {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 999;
		display: flex;
		background: rgba(0, 0, 0, 0.6);
		min-width: 0;
		&--center {
			justify-content: center;
			align-items: center;
		}
		&--top {
			align-items: flex-start;
			flex-basis: 100%;
		}
		&--right {
			justify-content: flex-end;
		}
		&--left {
			justify-content: flex-start;
		}
		& > div {
			outline: 0;
		}
	}
	&__body {
		position: relative;
		display: grid;
		grid: auto auto 1fr/1fr;
		padding: 16px;
		padding-top: 0;
		border-radius: 16px;
		background: #16233b linear-gradient(to bottom, #5199fd42 0, #5199fd00 62px);
		width: 100%;
		margin: 0 16px;
		outline: 0;
		min-width: 0;
		align-content: flex-start;
		.ltmd({
      height: auto !important;
      max-height:90vh;
    });
		.md({
      padding: 32px;
      padding-top: 0;
      min-width: 320px;
      width: auto;
    });
	}
	&__header {
		align-self: center;
		height: 70px;
		line-height: 70px;
		color: #ffffff;
		font-weight: bold;
		font-size: 18px;
		text-align: center;
	}
	&__icon {
		position: absolute;
		top: -100px;
		text-align: center;
		justify-self: center;
	}
	&__main {
		margin-top: 64px;
		color: #fff;
		line-height: 1.5;
    overflow: hidden auto;
	}
	&__icon + &__header {
		// margin-top: 36px;
	}

	&__icon + &__main {
		margin-top: 72px;
	}
	&__header + &__main {
		margin-top: 0;
	}

	&__close-button {
		position: absolute;
		right: 16px;
		top: 16px;
		transition: transform 300ms;
		fill: #fff;
		width: 20px;
		svg {
			width: inherit;
		}
		&:hover {
			transform: rotateZ(90deg);
		}
		& > img {
			width: 12px;
		}
	}
	&__footer {
		display: flex;
		gap: 16px;
		margin-top: 16px;
		min-width: 0;

		& > button {
			flex: 1;
		}
	}
}
.j9bc-modal-orange {
	.j9bc-modal();
	&__body {
		padding: 0 24px 24px;
		background: #31313f;
	}
	&__header {
		margin-top: 16px;
    height: 68px;
	}
	&__footer {
		.j9bc-btn {
			--btn-color: #ff7400;
		}
	}
	&__main {
		margin: 0;
		overflow: visible;
	}
}
