@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-Swap-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  // padding-bottom: 60px;

  &-bg{
    width: 100%;
    // margin-bottom: 20px;
  }
  &-content {
    height: auto;
    box-sizing: border-box;
    border-radius: 16px;
    background: rgba(29, 42, 67, 1);
    box-shadow: inset 0px -1px 0px 0px rgba(36, 49, 74, 1);
    position: relative;
    background-image: linear-gradient(175.8deg, rgba(81,153,253,0.26) 0%, rgba(81,153,253,0) 80px);

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 16px 16px 0px 0px;
      // background: rgba(29, 42, 67, 1);
      box-shadow: inset 0px -1px 0px 0px rgba(36, 49, 74, 1);
      padding: 24px;
      &-left {
        p {
          &:nth-child(1) {
            color: rgba(255, 255, 255, 1);
            font-size: 24px;
            line-height: 24px;
            &.en{
              font-family:Montserrat-SemiBold;
            }
          }

          &:nth-child(2) {
            padding-top: 8px;
            color: rgba(119, 137, 171, 1);
            font-size: 14px;
          }
        }
      }

      &-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        i {
          cursor: pointer;
          &:nth-child(1) {
            color: rgba(255, 255, 255, 1);
            font-size: 30px;
            margin-right: 10px;
          }

          &:nth-child(2) {
            // height: 50px;
            // width: 50px;
            // background: url(./images/loading.svg);
            // color: rgba(119, 137, 171, 1);
            .iconfont{
              font-size: 30px;
            }
          }
        }
        img{
          cursor: pointer;
          &.active{
            animation: rotation 2.5s;
          }
          @keyframes rotation{

            from  {-webkit-transform: rotate(0deg);}

            to  {-webkit-transform: rotate(360deg);}

          }
        }
      }
    }
    &-main {
      padding: 24px;
      &-ammout{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        &-left{
          display: flex;
          justify-content: flex-start;
          align-items: center;

          img{
            height: 20px;
            margin-right: 8px;
            image-rendering: -webkit-optimize-contrast;
          }
          &-J9BC{
            color: rgba(248,161,55,1);
            &.en{
              font-family:Montserrat-SemiBold;
            }
          }
          &-USDT{
            color: rgba(38,161,123,1);
            &.en{
              font-family:Montserrat-SemiBold;
            }
          }
          font-size: 14px;
          line-height: 22px;
        }
        &-right{
          color: rgba(119,137,171,1);
          font-size: 12px;
          line-height: 1.5;
        }
      }
      &-input{
        .outline-input-new{
          height: 44px;
        }
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
         input::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }
        &-all{
          width: 60px;
          color: rgba(111,169,250,1);
          font-size: 12px;
          letter-spacing: 0px;
          text-align: left;
          cursor: pointer;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .iconfont{
            color: #50607f;
            font-size: 20px;
          }
          span{
            padding: 16px 0 16px 16px;
          }
        }
      }

      &-switch{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &-icon{
          padding: 16px ;
          span{
            height: 24px;
            width: 24px;
            border-radius: 40px;
            background: rgba(111,169,250,0.2);
            display: flex;
            justify-content: center;
            align-items: center;
            i{
              color: #6FA9FA;
            }
          }
        }
      }
      &-refreshunit{
        height: 40px;
        margin:24px  0  0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        background: rgba(36,49,74,1);
        padding: 0 16px;
        &.en{
          font-family:Montserrat-SemiBold;
        }
        &-left{
          color: #7789AB ;
        }
        &-right{
          display: flex;
          justify-content: flex-end;
          align-items: center;
          &-strong{
            padding-right: 10px;
            font-weight: normal;
            color: #fff;
          }
          span{
            height: 24px;
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background: rgba(111,169,250,0.2);
            cursor: pointer;
          }
        }

      }
      &-percent{
        margin-top: 24px;
        &-top{
          color: rgba(255,255,255,1);
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 10px;
          &.en{
            font-family:Montserrat-SemiBold;
          }
        }
        &-list{
          // display: flex;
          // justify-content: space-between;
          // align-items: center;
          // box-sizing: border-box;
          // flex-wrap: wrap;
          box-sizing: border-box;
          display: grid;
          gap: 8px;
          grid-template-columns: repeat(2,1fr);
          &.legth2{
            grid-template-columns: repeat(2,1fr);
          }
          &.legth3{
            grid-template-columns: repeat(3,1fr);
          }
          &.legth4{
            grid-template-columns: repeat(4,1fr);
          }
          &.legth5{
            grid-template-columns: repeat(4,1fr);
            grid-template-rows: repeat(2,44px);
          }
          li{
            height: 44px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            color: rgba(157,177,216,0.6);
            border: 2px solid rgba(36,49,74,1);
            &.active{
              background: rgba(111,169,250,1);
              border: 2px solid rgba(111,169,250,1);
              color: #ffffff;
            }

          }
        }
      }
      &-submite{
        height: 48px;
        border-radius: 10px;
        background: rgba(36,49,74,1);
        display: flex;
        color: #50607F;
        font-weight: bold;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 32px;
        font-family:Montserrat-SemiBold;
        &.active{
          background: rgba(111,169,250,1);
          color: #ffffff;
        }
      }
    }

  }
}











.hx-modal-content.Dialog-action{
  background: none;
}
.j9bc-swap-dialog {
  overflow: visible;
  border-radius: 16px;
  // background: chartreuse;
  background-image: linear-gradient(175.8deg, rgba(81,153,253,0.26) 0%, rgba(81,153,253,0) 92px);
  .Dialog-content{
    padding-top: 0!important;
  .cat {
      width: 148px;
      position: fixed;
      top: -100px;
      right: 100px;
  }
  .j9bc-swap-dialog-box {
      width: 100%;
  }
  .j9bc-swap-dialog-content {
      width: 100%;
      color: #FFFFFF;
      font-family: Montserrat;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 0px;
      text-align: center;
      line-height: 32px;
      p{
        color: rgba(255,255,255,1);
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
        padding-top: 12px;
      }
      span{
        display: inline-block;
        color: rgba(157,177,216,1);
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: center;
        margin-top: 8px;
      }
  }
  }
  .Dialog-footer{
    .rightBtn {
      margin-top: 0px !important;
      border: none !important;
      border-radius: 10px !important;
      background: #6FA9FA !important;
      color: #FFFFFF !important;
      line-height: 48px !important;
      width: 200px !important;
      height: 48px !important;
      margin-bottom: 32px!important;
  }
  }


}