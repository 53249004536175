@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-icon {
	display: inline-grid;
	grid-auto-flow: column;
	place-items: center;
	gap: var(--gap);
	position: relative;
  font-size: inherit;
  line-height: 1;
	&--vertical {
		flex-direction: column;
	}

	&__img {
		width: 1em;
		height: 1em;
		object-fit: cover;
	}

	&__icon {
		font-size: 100%;
		font-style: normal;
	}

	&__badge {
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(50%, -50%);
		border-radius: 100px;
		background: #FF2020;
		color: #fff;
		font-size: 12px;
		height: 16px;
		line-height: 16px;
		min-width: 16px;
		text-align: center;
		padding: 0 2px;
		font-style: normal;
    box-sizing: border-box;
	}
}
