@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-locker {
	position: relative;
	border-radius: inherit;
  min-width: 0;
  grid-area: var(--name);

	&__overlay {
		position: absolute;
		left: 0;
    right: 0;
    top: 0;
    bottom: 0;
		background-color: rgba(32, 47, 75, 0.85);
		backdrop-filter: blur(8px);
		display: grid;
		place-items: center center;
		place-content: center center;
		z-index: 10;
		gap: 16px;
		overflow: hidden;
		border-radius: inherit;
	}

	&__icon {
		width: 66px;
	}

	&__txt {
		text-align: center;
		font-size: 20px;
		font-weight: 600;
    color: #fff;
	}
}
