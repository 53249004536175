@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);

.j9bc-swap-introduction {
  // max-width: 1424px;
  padding-bottom: 24px;
  margin: 16px  auto 0;
  &_info {
    grid-column: span 2;
    padding: 24px;
    border-radius: 16px;
    border-radius: 16px;
    background: rgba(30,34,55,1);
    &_ul {
      &_li {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        &:last-child{
          margin-bottom: 0;
        }
        &_h5 {
          color: rgba(157,177,216,1);
          font-family: PingFang SC;
          font-weight: bold;
          font-size: 16px;
          line-height: 2;
        }

        &_p {
          color: rgba(157,177,216,0.8);
          font-family: PingFang SC;
          font-weight: regular;
          font-size: 14px;
          line-height: 1.5;
        }

        &_img {
          width: 72px;
          object-fit: contain;
          margin-right:16px;
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .j9bc-swap-introduction {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 960px) {
  .j9bc-swap-introduction {
    max-width: 1424px;
    padding-bottom: 24px;
    margin: 16px  auto 0;
    &_info {
      &_ul{
        &_li{
          flex-direction: column;
          align-items: flex-start;
          &>div{
            display:flex;
            justify-content:flex-start;
            align-items: center;
            height: 38px;
            margin-bottom: 8px;
          }
          &_img{
            width: 50px;
            margin-right: 6px;
          }
          &_p{
            color: rgba(157,177,216,0.8);
            font-family: PingFang SC;
            font-weight: regular;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0px;
            text-align: left;

          }
        }
      }
    }
  }
  .swap-container > section {
    grid-template-columns: 1fr !important;
    justify-items: center;
    .Swap-wrap {
      width: 100%;
      max-width: 464px;
    }
  }


}
