@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
// regular = 400 , medium = 500 ,semibold = 600 , extra-bold = 800

.load-font(@n) {
	font-family: "Montserrat";
	src: url("../fonts/Montserrat/latin.woff2"), url("../fonts/Montserrat/other.woff2");
	font-weight: @n;
}

@font-face {
	.load-font(100);
}
@font-face {
	.load-font(200);
}
@font-face {
	.load-font(300);
}
@font-face {
	.load-font(400);
}
@font-face {
	.load-font(500);
}
@font-face {
	.load-font(600);
}
@font-face {
	.load-font(700);
}
@font-face {
	.load-font(800);
}
@font-face {
	.load-font(900);
}

