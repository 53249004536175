@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-Swap-History-Wrap{
  // max-width: 1424px;
  margin: 0 auto;
  padding: 24px 24px 20px 24px;
  border-radius: 16px;
  background: rgba(30,34,55,1);
  &-header{
    height: 37px;
    display: flex;
    justify-content: space-between;
    align-items:center;
    &-left{
      color: rgba(187,191,199,1);
      font-family: PingFang SC;
      font-weight: regular;
      font-size: 18px;
    }
    &-right{
      width: auto;
      height: 37px;
      border-radius: 8px;
      background: rgba(37,42,64,1);
      padding: 0 12px;
      box-sizing: border-box;
      .zent-popover-wrapper{
        width: 100%;
        height: 100%;
        &>button{
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 100%;
          img{
            width: 12px;
          }
          span{
            color: rgba(255,255,255,1);
            font-family: PingFang SC;
            font-weight: semibold;
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: 0px;
            text-align: left;
            padding: 0 15px 0 5px ;
          }
        }
      }
    }
  }
  &-List{
    overflow: hidden;
    &-li{
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 56px;
      line-height: 56px;
      background: rgba(30,34,55,1);
      box-shadow: inset 0px -1px 0px 0px rgba(157,177,216,0.08);
      &-p{
        flex: 1;
        text-align: center;
        color: rgba(179,196,229,1);
        font-family: PingFang SC;
        font-weight: regular;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 18px;
        &-img{
          width: 16px;
          margin-right: 6px;
        }
      }
      &:nth-child(1){
        margin: 16px 0 8px 0;
        height: 21px;
        line-height: 21px;
        box-shadow: none;
        p{
          color: rgba(157,177,216,0.6);
          font-family: PingFang SC;
          font-weight: medium;
          font-size: 14px;
          line-height: 1.5;
          
        }
      }
    }
  }
} 
.zent-portal.record_list_swap {
  .filter_box{
    border-radius: 8px;
    background: rgba(44,54,85,1);
    height: auto;
    padding: 14px 0 0 0;
    li{
      height: 30px;
      margin-bottom: 14px;
      span{
        color: rgba(179,196,229,1);
        font-family: PingFang SC;
        font-weight: regular;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0px;
        text-align: left;

      }
    }
  }
}
.Swap-Pagination{
  .rc-pagination{
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items:center;
    margin-top: 24px;
    li{
      height: 100%;
      width: 30px;
      
      .re_prev,.re_next{
        height: 30px;
        width: 30px;
      }
    }
  }
}
 .rc-pagination-jump-next button{
  opacity: 0.44999998807907104;
  color: rgba(157,177,216,1);
  font-family: Montserrat;
  font-weight: SemiBold;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;

}
.j9bc-EmptyBlock-Wrap{
  padding: 36px 0;
}
@media screen and (max-width: 768px) {
  
  .j9bc-Swap-History-Wrap{
    .j9bc-Swap-History-Wrap-header{
      margin-bottom: 12px;
    }
    .j9bc-Swap-History-Wrap-header-right{
      align-self: flex-end;
      padding: 0;
      .zent-popover-wrapper{
        &>button{
          padding: 0 12px;
        }
      }
    }
    &.en{
      .j9bc-Swap-History-Wrap-header{
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        .j9bc-Swap-History-Wrap-header-right{
          align-self: flex-end;
          margin-top: 8px;
        }
      }
    }
    
  }
  
}
