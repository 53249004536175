@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
@import "~rc-tabs/assets/index.css";
.rc-tabs {
	border: none;
}
.rc-tabs-tab {
	background-color: transparent;
	color: #ffffff99;
}
.rc-tabs-top .rc-tabs-ink-bar,
.rc-tabs-bottom .rc-tabs-ink-bar {
	height: 2px;
	border-radius: 2px;
}
.rc-tabs-nav .rc-tabs-ink-bar {
	height: 4px;
	bottom: 4px;
	z-index: -1;
	border-radius: 4px;
	background-color: var(--hx1-highlight-color);
}
.rc-tabs-nav,
.rc-tabs-nav-wrap,
.rc-tabs-tab-btn,
.react-tabs__tab-panel,
.rc-tabs-tabpane,
.rc-tabs-content,
.rc-tabs-content-holder {
	&:focus {
		outline: none !important;
		box-shadow: none !important;
	}
}
.rc-tabs-tab-active {
	color: #fff;
	font-weight: normal;
}

.rc-tabs-top .rc-tabs-ink-bar,
.rc-tabs-bottom .rc-tabs-ink-bar {
	height: 2px;
	border-radius: 2px;
}

.rc-tabs-nav .rc-tabs-ink-bar {
	height: 4px;
	bottom: 0;
	z-index: -1;
	border-radius: 4px;
	background-color: #ffb52f;
}

.rc-tabs-content-holder {
	margin-top: 14px;
}

.rc-tabs-nav,
.rc-tabs-nav-wrap,
.rc-tabs-tab-btn,
.react-tabs__tab-panel,
.rc-tabs-tabpane,
.rc-tabs-content,
.rc-tabs-content-holder {
	&:focus {
		outline: none !important;
		box-shadow: none !important;
	}
}

.rc-tabs-nav {
	.rc-tabs-tab {
		font-size: 16px;
		margin-left: 14px;
		margin-bottom: 4px;
		.rc-tabs-tab-btn {
			line-height: 32px;
		}
		&:nth-child(1) {
			margin-left: 0;
		}
	}

	.rc-tabs-tab-active {
		color: #fff;
		font-size: 18px;
		font-weight: 500;
	}
}
