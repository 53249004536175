@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-loancom {
  margin-top: 16px;

  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &_main {
    border-radius: 25px 16px 16px 16px;
    background: rgba(40, 42, 47, 1);
    position: relative;
    display: flex;
    align-items: center;

    &_left {
      flex: 1;
      background-image: url('../../img/bg1.png');
      background-repeat: no-repeat;
      background-size: 100%;
      position: relative;
      height: 348px;
      border-radius: 16px;

      &_img {
        position: absolute;
        width: 432px;
        height: 322px;
        top: 26px;
        left: -20px;
        border-radius: 16px;
      }

      &_div {
        position: relative;
        margin: 32px 64px 32px 400px;

        &_title {
          color: rgba(224, 224, 224, 1);
          font-size: 14px;
        }

        &_title2 {
          margin-top: 16px;
        }

        &_input {
          width: 100%;
          height: 56px;
          border-radius: 8px;
          margin-top: 8px;

          .outline-input-new {
            border-radius: 8px;
            background: rgba(38, 38, 41, 1);
            border: 1px solid rgba(69, 69, 89, 1);
          }

          .j9bc-loancom_after {
            display: flex;
            align-items: center;

            p {
              margin-right: 8px;
              color: rgba(255, 115, 0, 1);
              font-size: 12px;
              text-align: center;
              display: block;
              width: 40px;
              cursor: pointer;
            }

            img {
              width: 16px;
              height: 16px;
            }

            span {
              color: rgba(69, 69, 89, 1);
              margin-left: 4px;
              font-size: 14px;
              text-align: center;
            }
          }
        }

        &_q {
          display: flex;
          align-items: center;
          margin-top: 16px;

          &_text {
            color: rgba(224, 224, 224, 1);
            font-size: 14px;
            line-height: 22px;
            margin-right: 12px;
          }

        }

        &_btnbox {
          display: flex;
          align-items: center;
          margin-top: 8px;

          & &_btn:first-child {
            margin-right: 16px;
          }

          &_btn {
            width: 263.5px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            border-radius: 8px;
            border: 1px solid rgba(255, 255, 255, 0.2);
            cursor: pointer;
            font-size: 14px;
            color: rgba(255, 255, 255, 1);

            &:hover {
              border: 1px solid rgba(255, 116, 0, 1);
              color: rgba(255, 116, 0, 1);
            }
          }

          &_active {
            border: 1px solid rgba(255, 116, 0, 1);
            color: rgba(255, 116, 0, 1);
          }
        }

        &_hasdata {
          width: 608px;
          height: 348px;
          position: absolute;
          top: -32px;
          left: -10px;
          border-radius: 16px 16px 0px 0px;
          backdrop-filter: blur(4px);

          .lock_box {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin: auto;

            .lock_box_imgbox {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .lock_box_img {
              width: 72px;
              height: 72px;
            }

            .lock_box_span {
              margin-top: 12px;
              color: rgba(224, 224, 224, 1);
              font-size: 16px;
              text-align: center;
            }
          }
        }
      }
    }

    &_right {
      height: 348px;
      width: 416px;
      padding: 32px;
      border-radius: 16px;
      background: rgba(58, 60, 65, 1);

      & &_li:first-child {
        margin-top: 0;
      }

      &_li {
        display: flex;
        align-items: center;
        height: 46px;
        border-bottom: 1px solid rgba(69, 82, 115, 0.15);
        margin-top: 10px;

        &_text {
          color: rgba(255, 255, 255, 0.8);

          width: 150px;
          font-size: 14px;
        }

        &_num {
          flex: 1;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          text-align: right;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &_btn {
        border-radius: 8px;
        background: rgba(255, 116, 0, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        line-height: 50px;
        text-align: center;
        margin-top: 20px;
        cursor: pointer;
      }
      &_btnfalse{
        opacity: 0.40000001;
        background: #ff7400;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        line-height: 50px;
        text-align: center;
        margin-top: 20px;
        cursor: pointer;
      }
    }
  }

  &_bottom {
    border-radius: 16px;
    background: rgba(40, 42, 47, 1);
    background-image: url('../../img/bg2.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: rgba(40, 42, 47, 1);
    height: 80px;
    display: flex;
    align-items: center;
    padding: 15px 24px;
    margin-top: 32px;

    &_text {
      flex: 1;
      margin-left: 10px;
    }

    &_btn {
      cursor: pointer;
      width: 148px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
      border-radius: 8px;
      background: linear-gradient(-90deg, rgba(255, 116, 0, 1) 0%, rgba(226, 106, 52, 1) 100%);
    }

    i {
      font-size: 18px;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }

  .j9bc-hover-question {
    margin-left: 8px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: relative;

    i {
      width: 14px;
      height: 14px;
      font-size: 14px;
      cursor: pointer;
    }

    &:hover {
      .j9bc-loan-rulediv {
        display: block;
      }
    }

    .j9bc-loan-rulediv {
      position: absolute;
      top: calc(-10px - 76px);
      left: -22px;
      z-index: 1;
      display: none;
    }

    .j9bc-loan-ruledivmain {
      width: 246px;
      border-radius: 8px;
      background: #7789AB;
      color: #FFFFFF;
      font-family: PingFang SC;
      font-weight: regular;
      font-size: 14px;
      line-height: 22px;
      padding: 16px;
      font-style: normal;

    }

    .j9bc-loan-rulej {
      width: 0;
      height: 0;
      margin-left: 20px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #7789AB;
    }
  }
}

.j9bc-loancom_subno {
  border-radius: 16px;
  background: rgba(49, 49, 63, 1);

  .j9bc-loancom_section {
    position: relative;
    width: 343px;
    padding: 33px;

    .j9bc-loancom_section_close {
      position: absolute;
      right: 14px;
      top: 14px;
    }

    .j9bc-loancom_section_h2 {
      text-align: center;
    }

    .j9bc-loancom_section_h3 {
      color: rgba(136, 136, 136, 1);
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      margin-top: 16px;
    }

    .j9bc-loancom_section_btnbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;

      .j9bc-loancom_section_cancel {
        color: #fff;
        font-size: 16px;
        line-height: 44px;
        letter-spacing: 0px;
        text-align: center;
        width: 295px;
        border-radius: 10px;
        background: rgba(255, 116, 0, 1);
        cursor: pointer;
      }

    }
  }
}

.j9bc-loancom_sub {
  border-radius: 16px;
  background: rgba(49, 49, 63, 1);

  .j9bc-loancom_section {
    position: relative;
    width: 343px;
    padding: 32px;

    .j9bc-loancom_section_close {
      position: absolute;
      right: 14px;
      top: 14px;
    }

    .j9bc-loancom_section_h3 {
      color: rgba(136, 136, 136, 1);
      font-size: 14px;
      line-height: 24px;
      text-align: center;
    }

    .j9bc-loancom_section_btnbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;

      .j9bc-loancom_section_cancel {
        color: rgba(255, 116, 0, 1);
        font-size: 16px;
        line-height: 44px;
        letter-spacing: 0px;
        text-align: center;
        width: 131px;
        border-radius: 10px;
        border: 1px solid rgba(255, 116, 0, 1);
        cursor: pointer;
      }

      .j9bc-loancom_section_confirm {
        cursor: pointer;
        border-radius: 10px;
        border: 1px solid rgba(255, 116, 0, 1);
        background: rgba(255, 116, 0, 1);
        width: 131px;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        line-height: 44px;
        text-align: center;
      }
    }
  }
}

.j9bc-loancom2_sub {
  border-radius: 16px;
  background: rgba(49, 49, 63, 1);

  .j9bc-loancom2_section {
    position: relative;
    width: 343px;
    padding: 32px;

    .j9bc-loancom2_section_close {
      position: absolute;
      right: 14px;
      top: 14px;
    }

    .j9bc-loancom2_section_h2 {
      color: rgba(255, 255, 255, 1);
      font-size: 18px;
      line-height: 22px;
      text-align: center;
    }

    .j9bc-loancom2_section_h3 {
      color: rgba(136, 136, 136, 1);
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      margin-top: 8px;
    }

    .j9bc-loancom2_nav {
      color: rgba(111, 169, 250, 1);
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      margin-left: 60px;
      margin-top: 16px;
    }

    .j9bc-loancom2_nav_li {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-top: 6px;
    }

    .j9bc-loancom2_section_btnbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;

      .j9bc-loancom2_section_cancel {
        color: rgba(255, 116, 0, 1);
        font-size: 16px;
        line-height: 44px;
        letter-spacing: 0px;
        text-align: center;
        width: 131px;
        border-radius: 10px;
        border: 1px solid rgba(255, 116, 0, 1);
        cursor: pointer;
      }

      .j9bc-loancom2_section_confirm {
        cursor: pointer;
        border-radius: 10px;
        border: 1px solid rgba(255, 116, 0, 1);
        background: rgba(255, 116, 0, 1);
        width: 131px;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        line-height: 44px;
        text-align: center;
      }
    }
  }
}

.j9bc-loansusspop {
  background: rgba(49, 49, 63, 1);
  padding: 32px;
  position: relative;
  overflow: visible;
  border-radius: 16px;
  .j9bc-loansusspop_section {
    background: rgba(49, 49, 63, 1);
    border-radius: 16px;

  }

  .j9bc-loansusspop_section_h2 {
    color: rgba(255, 255, 255, 1);
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }

  .j9bc-loansusspop_section_h3 {
    color: rgba(136, 136, 136, 1);
    font-family: PingFang SC;
    font-weight: regular;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 16px;

  }

  .j9bc-loansusspop_section_close {
    position: absolute;
    right: 14px;
    top: 14px;
    z-index: 1;
  }

  .j9bc-loansusspop_section_img {
    position: absolute;
    width: 162px;
    height: 141px;
    top: -93px;
    left: 0;
    right: 0;
    margin: auto;
  }

  .j9bc-loansusspop_section_btn {
    margin-top: 16px;
    border-radius: 10px;
    background: rgba(255, 116, 0, 1);
    width: 100%;
    height: 44px;
    line-height: 44px;
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    text-align: center;
    cursor: pointer;
  }
}
.j9bc-loancom_sub,.j9bc-loancom_subno,.j9bc-loansusspop,.j9bc-loancom2_sub{
  .j9bc-modal__close-button{

    display:none;
  }
  .j9bc-modal__header{
    display:none;
  }
  .j9bc-modal__footer{
    display:none;
  }
  .j9bc-modal__main{
    margin-top:0 !important;
  }
}
@media screen and (max-width:1300px) {
  .j9bc-loancom_main_left_img {
    display: none;
  }
  .j9bc-loancom_main_left_div{
    margin: 32px 64px 32px 64px;
  }
  .j9bc-loancom_main_left_div_hasdata{
    width: 100%;
    left: 0;
  }
}
@media screen and (max-width:960px) {
  .j9bc-loancom {
    margin-top: 16px;

    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }

    &_main {
      border-radius: 25px 16px 16px 16px;
      background: rgba(40, 42, 47, 1);
      position: relative;
      display: block;
      padding-top: 50px;

      &_left {
        flex: 1;
        position: relative;
        height: auto;
        border-radius: 16px;
        background-image: none;
        padding-bottom: 16px;

        &_img {
          position: absolute;
          width: 432px;
          height: 322px;
          top: 26px;
          left: -20px;
          border-radius: 16px;
          display: none;
        }

        &_div {
          position: relative;
          margin: 0;

          &_title {
            padding: 20px 16px 0;
            color: rgba(224, 224, 224, 1);
            font-size: 14px;
          }

          &_title2 {
            padding: 20px 16px 0;
            margin-top: 0;
          }

          &_input {
            padding: 0 16px;
            width: auto;
            height: 56px;
            border-radius: 8px;
            // margin-top: 8px;
            margin: 8px 16px 0;

            .outline-input-new {
              border-radius: 8px;
              background: rgba(38, 38, 41, 1);
              border: 1px solid rgba(69, 69, 89, 1);
            }

            .loancom_after {
              display: flex;
              align-items: center;

              p {
                margin-right: 8px;
                color: rgba(255, 115, 0, 1);
                font-size: 12px;
                text-align: center;
                display: block;
                width: 40px;
                cursor: pointer;
              }

              img {
                width: 16px;
                height: 16px;
              }

              span {
                color: rgba(69, 69, 89, 1);
                margin-left: 4px;
                font-size: 14px;
                text-align: center;
              }
            }
          }

          &_q {
            padding: 0 16px;
            display: flex;
            align-items: center;
            margin-top: 16px;

            &_text {
              color: rgba(224, 224, 224, 1);
              font-size: 14px;
              line-height: 22px;
              margin-right: 12px;
            }

            i {
              width: 14px;
              height: 14px;
              font-size: 14px;
              cursor: pointer;
            }
          }

          &_btnbox {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            grid-gap: 16px;
            gap: 16px;
            margin: 8px 16px 0;

            & &_btn:first-child {
              margin-right: 0;
            }

            &_btn {
              width: auto;
              height: 50px;
              line-height: 50px;
              text-align: center;
              border-radius: 8px;
              border: 1px solid rgba(255, 255, 255, 0.2);
              cursor: pointer;
              font-size: 14px;
              color: rgba(255, 255, 255, 1);

              &:hover {
                border: 1px solid rgba(255, 116, 0, 1);
                color: rgba(255, 116, 0, 1);
              }
            }

            &_active {
              border: 1px solid rgba(255, 116, 0, 1);
              color: rgba(255, 116, 0, 1);
            }
          }

          &_hasdata {
            width: 100%;
            height: calc(100% + 16px);
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 16px 16px 0px 0px;
            backdrop-filter: blur(4px);

            .lock_box {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              margin: auto;

              .lock_box_imgbox {
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .lock_box_img {
                width: 72px;
                height: 72px;
              }

              .lock_box_span {
                margin-top: 12px;
                color: rgba(224, 224, 224, 1);
                font-size: 16px;
                text-align: center;
              }
            }
          }
        }
      }

      &_right {
        height: 348px;
        width: 100%;
        padding: 32px;
        border-radius: 0 0 16px 16px;
        background: rgba(58, 60, 65, 1);

        & &_li:first-child {
          margin-top: 0;
        }

        &_li {
          display: flex;
          align-items: center;
          height: 46px;
          border-bottom: 1px solid rgba(69, 82, 115, 0.15);
          margin-top: 10px;

          &_text {
            color: rgba(255, 255, 255, 0.8);
            flex: 1;
            font-size: 14px;
          }

          &_num {
            width: 81px;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            text-align: right;

          }
        }

        &_btn {
          border-radius: 8px;
          background: rgba(255, 116, 0, 1);
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          line-height: 50px;
          text-align: center;
          margin-top: 20px;
          cursor: pointer;
        }
      }
    }

    &_bottom {
      border-radius: 16px;
      background: rgba(40, 42, 47, 1);
      background-color: rgba(40, 42, 47, 1);
      display: block;
      padding: 15px 24px;
      margin-top: 32px;
      height: auto;

      &_text {
        flex: 1;
        margin-left: 0;
      }

      &_btn {
        cursor: pointer;
        width: auto;
        height: 50px;
        line-height: 50px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        border-radius: 8px;
        margin: 16px auto;
        background: linear-gradient(-90deg, rgba(255, 116, 0, 1) 0%, rgba(226, 106, 52, 1) 100%);
      }

      i {
        font-size: 18px;
        width: 18px;
        height: 48px;
        cursor: pointer;
      }
    }
  }
}