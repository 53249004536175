@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-loanrule {
  margin-top: 32px;

  &_header {
    &_title {
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
      line-height: 40px;
    }

    &_del {
      color: rgba(255, 255, 255, 0.6);
      font-size: 16px;
      line-height: 24px;
    }
  }

  &_list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 16px;

    .j9bc-loanrule__QA__item {
      border-radius: 16px;
      background: rgba(40, 42, 47, 1);
      margin-bottom: 16px;

      .j9bc-loanrule__QA__button {
        padding: 24px;
        color: #fff;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        position: relative;
      }

      .j9bc-loanrule_icon {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 24px;
        top: 0;
        bottom: 0;
        margin: auto;

        i {
          font-size: 16px;
          color: rgba(136, 136, 136, 1);
        }
      }
    }
  }

  .j9bc-loanrule_list_QA-container-collapse div {
    color: rgba(255, 255, 255, 0.6);
    padding: 0 24px 24px;
    font-size: 16px;
    line-height: 24px;
    white-space: pre-wrap;

  }

  .collapse-css-transition {
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@media screen and (max-width:960px) {
  .j9bc-loanrule {
    margin-top: 32px;

    &_header {
      &_title {
        color: rgba(255, 255, 255, 1);
        font-size: 24px;
        line-height: 40px;
      }

      &_del {
        color: rgba(255, 255, 255, 0.6);
        font-size: 16px;
        line-height: 24px;
      }
    }

    &_list {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0;

      .j9bc-loanrule__QA__item {
        border-radius: 16px;
        background: rgba(40, 42, 47, 1);
        margin-bottom: 16px;

        .j9bc-loanrule__QA__button {
          padding: 24px;
          color: #fff;
          font-size: 16px;
          line-height: 24px;
          cursor: pointer;
          position: relative;
        }

        .j9bc-loanrule_icon {
          width: 16px;
          height: 16px;
          position: absolute;
          right: 24px;
          top: 0;
          bottom: 0;
          margin: auto;

          i {
            font-size: 16px;
            color: rgba(136, 136, 136, 1);
          }
        }
      }
    }

    .j9bc-loanrule_list_QA-container-collapse div {
      color: rgba(255, 255, 255, 0.6);
      padding: 0 24px 24px;
      font-size: 16px;
      line-height: 24px;
    }
  }

}