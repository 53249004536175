@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-pledge__chart {
	background-image: linear-gradient(-90deg, #293b7b 0%, #1d2a43 100%);
	height: 430px;
	border-radius: 16px;
	padding: 16px;
	display: flex;
	flex-direction: column;
	grid-area: chart;
	position: relative;

	.md({
    padding: 32px;
    padding-right: 300px;
  });

	&__title {
		color: #6fa9fa;
		font-size: 20px;
		line-height: 30px;
		font-weight: 600;
		.md({
      font-size: 24px;
    });
	}
	&__label {
		font-size: 16px;
		line-height: 2;
		margin: 8px 0 20px 0;
		color: #9db1d8;
	}
	&__table {
		flex: 1;
	}
	&__items {
		padding-top: 22px;
		height: 100%;
		display: grid;
		--cols: 5;
		grid-template-columns: repeat(var(--cols), minmax(30px, 1fr));
		gap: 20px;
		.md({
      --cols:7;
      gap: 64px;
      padding-right: 48px;
    });
	}

	&__item {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
	&__item:hover &__bar {
		background-image: linear-gradient(180deg, #6dffff 0%, #b6dcff 100%);
		&:after {
			opacity: 0.8;
		}
	}

	&__amount {
		white-space: nowrap;
		width: 0;
		display: flex;
		justify-content: center;
		margin: 0 auto;
		line-height: 22px;
		color: #9db1d8;
	}
	&__bar {
		position: relative;
		border-radius: 8px 8px 0 0;
		background-image: linear-gradient(180deg, #6d8dff 0%, #b6c1ff 100%);
		animation: 3s plege-grow;
		transform-origin: bottom;
		&::after {
			content: "";
			position: absolute;
			left: 50%;
			top: 0;
			transform: translate(-50%, -50%);
			width: 7px;
			height: 7px;
			border-radius: 50%;
			opacity: 0;
			background-color: #fff;
			border: 5px solid #d0d0d0;
			transition: opacity 0.3s ease-in-out;
		}
	}

	&__date {
		line-height: 1;
		margin-top: 8px;
		text-align: center;
		color: #99acd0;
		font-size: 14px;
	}

	&__img {
		display: none;
		.md({
      display: block;
      position: absolute;
      top: -32px;
      width: 300px;
      pointer-events: none;
      right: 0;
    });
	}
}

@keyframes plege-grow {
	from {
		transform: scaleY(0);
	}
	to {
		-webkit-transform: scaleY(1);
	}
}
