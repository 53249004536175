+b( "btn" )
  display inline-grid
  width var( --btn-width )
  height var( --btn-height )
  border 1px solid var( --btn-color )
  border-radius var( --btn-radius )
  line-height 1
  cursor pointer
  transition all 200ms ease-in
  place-items center center
  grid-auto-flow column
  gap 8px

  &--disabled:disabled
    // background-color #24314a
    cursor not-allowed
    --btn-color #50607F
    background-color #888

  +m( solid )
    background-color var( --btn-color )
    color #fff

    &:not(:disabled):hover
    &:not(:disabled):active
      background-color rgba( 0, 0, 0, 0 )
      color var( --btn-color )

  +m( outline )
    background-color rgba( 0, 0, 0, 0 )
    color var( --btn-color )

    &:not(:disabled):hover
      background-color var( --btn-color )
      color #fff

  +m( "none" )
    border-width 0;
    color var( --btn-color );

    &:not(:disabled):hover
      background-color var( --btn-color )
      color #fff

  +m( "block" )
    display grid
    width 100%

  +m( primary )
    --btn-color #6fa9fa

  +m( secondary )
    --btn-color #6fa9fa

  +m( success )
    --btn-color #69CD75

  +m( danger )
    --btn-color #6fa9fa

  +m( warn )
    --btn-color #FF7400

  +m( info )
    --btn-color #6fa9fa

  +m( mini )
    padding 0 4px
    font-size 12px
    --btn-radius 2px
    --btn-height 16px

  +m( small )
    padding 0 8px
    font-size 12px
    --btn-radius 4px
    --btn-height 24px

  +m( normal )
    padding 0 16px
    font-size 14px
    --btn-radius 8px
    --btn-height 32px

  +m( medium )
    padding 0 16px
    font-size 14px
    --btn-radius 8px
    --btn-height 40px

  +m( large )
    padding 0 24px
    font-size 16px
    --btn-radius 8px
    --btn-height 48px

  +m( circle-left )
    --btn-radius 999px 0 0 999px

  +m( circle-right )
    --btn-radius 0 999px 999px 0

  +m( circle-all )
    --btn-radius 999px
