@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-pledge {
	display: grid;
	gap: 16px;
	// padding: 0 16px;
	grid-template-areas: "a" "header" "action" "rule" "b" "chart" "record" "c" "faq";
	.md({
    grid-template-areas:"a a" "header header" "action rule" "b b" "chart chart" "record record" "c c" "faq faq";
    // grid-template-columns: 0.6fr 0.4fr;
  });

	&__title {
		font-family: Montserrat-ExtraBold, sans-serif;
		font-size: 24px;
		line-height: 40px;
		font-weight: 600;
	}
  .rc-table-placeholder{
    height: 260px;
  }
}
