@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-pledge__record {
	min-width: 0;
	grid-area: record;
	position: relative;
	--swiper-pagination-bullet-inactive-color: #fff;
	--swiper-pagination-bullet-inactive-color: #fff;
	--swiper-pagination-color: #fff;
	margin-right: -16px;
  border-radius: 16px;
  &--locked{
    margin-right: 0;
  }
	.swiper-slide {
		background-color: #1d2a43;
		padding: 16px;
		height: 340px;
    width: calc(100% - 16px);
    border-radius: 16px;
	}
	.md({
    padding: 32px;
    overflow: hidden;
    height: 424px;
    margin-right: 0;
    background-color: #1d2a43;
		height: 340px;
  });

	&__table {
		color: #fff;
		font-size: 12px;
		text-align: center;
		white-space: nowrap;
		.md({
      font-size: 16px;
    });
		.rc-table-body {
			overflow-x: hidden;
		}
		table {
			width: 100%;
		}
		th {
			color: #50607f;
		}
	}
	&__row {
		height: 40px;
		border-bottom: 1px solid #293957;
		.md({
      height: 56px;
    });
	}
	.swiper {
		// overflow: visible;
		padding-bottom: 44px;
		touch-action: pan-y;
	}
	.swiper-pagination {
		// bottom: -30px !important;
	}

	&__title {
		color: #6fa9fa;
		font-family: Montserrat-ExtraBold, sans-serif;
		font-size: 24px;
		line-height: 40px;
		font-weight: 600;
		margin-right: 16px;
	}
	.rc-tabs-ink-bar {
		display: none;
	}
	.rc-tabs-tab {
		padding: 0 16px;
		font-size: 12px;
		color: rgba(255, 255, 255, 0.8);
		background-color: rgba(255, 255, 255, 0.2);
		line-height: 28px;
		height: 28px;
		border-radius: 8px;
		margin-right: 8px;
    font-size: 14px !important;
		&.rc-tabs-tab-active {
			background-image: linear-gradient(90deg, #69b7f8 0%, #6fa7fa 100%);
			color: #fff;
		}
	}
	.rc-tabs-nav {
		align-items: center;
	}
	.rc-tabs-extra-content {
		order: -1;
	}
	.rc-tabs-tabpane {
		.j9bc-locker {
			height: 300px !important;
		}
	}
}
