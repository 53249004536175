@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.j9bc-HuigouKline-Wrap{
  // width: 465px;
  // height: 260px;
  border-radius: 16px;
  background: rgba(29,42,67,0.8);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 24px 12px 24px 18px;
  flex-direction: column;
  &-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    // height: 27px;
    width: 100%;
    padding: 0 20px 20px 0;
    &-left{
      color: rgba(187,191,199,1);
      font-family: PingFang SC;
      font-weight: regular;
      font-size: 18px;
      line-height: 1.5;
      letter-spacing: 0px;
      text-align: left;
    }
    &-right{
      color: rgba(157,177,216,0.6);
      font-family: PingFang SC;
      font-weight: regular;
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 0px;
      text-align: left;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &-p{
        &:nth-child(1){
          display: none;
          margin-right: 16px;
          &-em{
            display: inline-block;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background: rgba(71,132,217,1);
            margin-right: 4.5px;
          }
        }
        &:nth-child(2){
          &-em{
            height: 12px;
            width: 12px;
            display: inline-block;
            border-radius: 50%;
            background: rgba(245,181,68,1);
            margin-right: 4.5px;
          }
        }
      }
    }
  }
  .j9bc-tooltipcontent{
    width: 152px;
    height: 115px;
    border-radius:16px;
    background: rgba(119,137,171,1);
    position: relative;
    box-sizing: border-box;
    padding: 10px 14px;
    width: 152px;
    height: 115px;
    
    width: 152px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    &-p{
      font-family: PingFang SC;
      &:nth-child(1){
        color: rgba(255,255,255,0.8);
        font-size: 12px;
        line-height: 18px;
      }
      &:nth-child(2), &:nth-child(4){
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: rgba(255,255,255,1);
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
      }
      &-img{
        width: 16px;
        margin-right: 4px;
      }
      &:nth-child(3){
        color: rgba(255,255,255,0.8);
        font-size: 12px;
        line-height: 18px;
        margin-top: 6px;
      }
    }

    // &::after{
    //   content: '';
    //   height: 50px;
    //   width: 50px;
    //   background:red;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   z-index: 100;
    // }
  }
  &.en{
    .HuigouKline-Wrap-header{
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: auto;
      .HuigouKline-Wrap-header-left{
        height: 27px;
        margin-bottom: 8px;
      }
      .HuigouKline-Wrap-header-right{
        height: 21px;
      }
    }

  }
} 

@media screen and (max-width: 768px) {
  .j9bc-HuigouKline-Wrap{
    width: 100%;
    padding: 24px  12px 24px 24px;
    margin-bottom: 16px;
    height:290px;
    .j9bc-HuigouKline-Wrap-header{
      flex-direction: column;
      align-items: flex-start;
      .HuigouKline-Wrap-header-right{
        padding-top: 8px;
      }
    }
    &.en{
      height: 318px;
      .j9bc-HuigouKline-Wrap-header{
        .j9bc-HuigouKline-Wrap-header-left{
          height: auto;
        }
      }
      
    }
  }
}
.recharts-cartesian-axis-line{
  display: none;
}
