@import url(/root/workspace/j9-bc-game-web/src/assets/styles/mixins.less);
.button-close {
  transition: all ease-in-out 200ms;
  padding: 10px;
  img {
    -webkit-user-drag: none;
  }
  &:hover {
    transform: rotate(90deg) scale(1.1);
  }
  &:active {
    transform: rotate(90deg) scale(1.1);
  }
}

@media screen and (max-width: 960px) {
  .button-close:hover {
    transform: initial;
  }
}